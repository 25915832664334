.component_delivery-method {
	cursor: pointer;
	position: relative;
	
	.icon {
		background: $colorGray;
		border-radius: 3px;
		height: 30px;
		padding: 6px;
		width: 30px;
	}
	
	b {
		font-weight: 700;
	}
	
	.button {
		border: 2px solid black;
		border-radius: 5px;
		height: 30px;
		position: relative;
		text-align: center;
		text-transform: uppercase;
		width: 30px;
	}
	
	&._active {
		
		&:before {
			background: $colorGray;
			border-radius: 3px;
			bottom: -.5rem;
			content: "";
			left: -.5rem;
			position: absolute;
			right: -.5rem;
			top: -.5rem;
		}
		
		.icon {
			background: $colorAcent;
			fill: #fff;
		}

    .button {
      background: url("../imgs/icons/checkbox.svg") 50%/80% no-repeat;
    }
	}
}
