.component_portfolio {
	overflow: hidden;
	padding: 40px 78px;
	aspect-ratio: 1/0.75;
	
	.icon {
		height: 54px;
		left: 0;
		padding: 20px;
		position: absolute;
		top: 0;
		width: 54px;
	}
	
	@media (max-width: $mediaXxl) {
		padding: 30px 30px 30px 80px;
	}
	
	@media (max-width: $mediaLg) {
		padding: 50px 15px 15px 15px;
		
		.icon {
			height: 38px;
			padding: 11px;
			width: 38px;
		}
	}
}
