.brd {
	&-rad3 {
		border-radius: 3px;
	}

  &-rad15 {
    border-radius: 15px
  }
	
	&-style1 {
		border-left: 1px solid $colorGray;
		border-top: 1px solid $colorGray;
	}
}

@each $breakpoint in map-keys($grid-breakpoints) {
	@include media-breakpoint-up($breakpoint) {
		$infix: breakpoint-infix($breakpoint, $grid-breakpoints);
		
		@each $size, $length in $spacers {
      .brd-rad#{$infix}-#{$size} {
        border-radius: $length !important;
      }
		}
	}
}
