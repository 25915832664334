.section_header-banner {
	padding-top: 50px;
	
	.swiper {
		&-container {
			overflow: hidden;
		}
		
		&-slide {
			min-height: 350px;
			padding: 70px 40px 40px;
		}
		
		&-pagination {
			bottom: 64px !important;
			display: flex;
			left: 260px !important;
			position: absolute;
			z-index: 1;
			
			&-bullet {
				background: $colorAcent;
				border-radius: 2px;
				cursor: pointer;
				height: 7px;
				width: 7px;
				
				&-active {
					background: #fff;
					position: relative;
					
					&:before {
						border: 1px solid $colorAcent;
						border-radius: 4px;
						content: "";
						height: 15px;
						left: -5px;
						position: absolute;
						top: -5px;
						width: 15px;
					}
				}
				
				&:not(:last-child) {
					margin-right: 30px;
				}
			}
		}
	}
	
	.component_promo-banner {
		height: 100%;
	}
	
	@media (max-width: $mediaXxl) {
		.swiper {
			&-slide {
				min-height: 300px;
				padding: 30px;
			}
			
			&-pagination {
				bottom: 50px !important;
				left: 240px !important;
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.swiper {
			&-slide {
				padding: 15px;
			}
			
			&-pagination {
				bottom: 35px !important;
				left: 210px !important;
				
				&-bullet {
					&:not(:last-child) {
						margin-right: 20px;
					}
				}
			}
		}
		
		.title {
			font-size: size(40);
		}
	}
	
	@media (max-width: $mediaLg) {
		.swiper {
			&-slide {
				min-height: 250px;
			}
		}
		
		.title {
			font-size: size(34);
		}
	}
	
	@media (max-width: $mediaSm) {
		.title {
			font-size: size(28);
		}
	}
}
