.page-error {
	&_404 {
		.bg-bg {
			overflow: hidden;
			
			.line {
				height: 2px;
				width: 140px;
			}
			
			.error {
				&-num {
					font-size: 150px;
					transform: rotate(-90deg) translate(40%, -30%);
				}
				
				&-img {
					bottom: -78px;
					position: absolute;
					right: 110px;
					width: 446px;
				}
			}
		}
	}
	
	@media (max-width: $mediaXxl) {
		&_404 {
			.bg-bg {
				.error {
					&-num {
						font-size: 120px;
						transform: rotate(-90deg) translate(40%, 10%);
					}
					
					&-img {
						width: 380px;
						right: 30px;
						bottom: -50px;
					}
				}
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		&_404 {
			.bg-bg {
				.error {
					&-num {
						left: auto;
						right: -20px;
						font-size: 100px;
						transform: rotate(-90deg) translate(40%, 0);
					}
				}
			}
		}
	}
	
	@media (max-width: $mediaLg) {
		&_404 {
			.bg-bg {
				.error {
					&-num {
						font-size: 80px;
						transform: rotate(-90deg) translate(0, 0);
					}
				}
			}
		}
	}
	
	@media (max-width: $mediaMd) {
		&_404 {
			.bg-bg {
				.fs-46 {
					font-size: 34px;
				}
				
				.fs-20 {
					font-size: 16px;
				}
			}
		}
	}
}
