@import "./variables";


html {
	color: $colorText;
	font: 400 #{$fontSize}px/1.4 $fontName;
}

/* Position */
[class*="pos-abs"] {
	position: absolute;
}

.pos {
	position: relative;
	z-index: 1;
	
	&-abs {
		left: 50%;
		top: 50%;
		transform: translate(-50%, -50%);
	}
	
	&-abs-y {
		top: 50%;
		transform: translate(0, -50%);
	}
	
	&-abs-x {
		left: 50%;
		transform: translate(-50%, 0);
	}
}

/* Image */
[class*="image-"] {
	height: 100%;
	width: 100%;
}

.image {
	&-cover {
		object-fit: cover;
	}
	
	&-contain {
		object-fit: contain;
	}
	
	&-circle {
		border-radius: 100%;
	}
}

/* Scroll */
[class*="scroll-"] {
	-webkit-overflow-scrolling: touch;
}

.scroll {
	&-y {
		overflow: hidden auto;
	}
	
	&-x {
		overflow: auto hidden;
	}
	
	&-custom {
		&::-webkit-scrollbar {
			background: #fff;
			border-radius: 2px;
			width: 8px;
		}
		
		&::-webkit-scrollbar-thumb {
			background: #bababb;
			border-radius: 2px;
		}
	}
	
	&-disabled {
		overflow: hidden;
	}
}

/* Cursor */
.cursor {
	&-pointer {
		cursor: pointer !important;
	}
	
	&-default {
		cursor: default !important;
	}
}

/* Z index */
.z {
	&-1 {
		z-index: 1;
	}
	
	&-2 {
		z-index: 2;
	}
	
	&-n1 {
		z-index: -1;
	}
	
	&-n2 {
		z-index: -2;
	}
}

/* Other */
.js-open-modal {
	cursor: pointer;
	
	& > * {
		pointer-events: none;
	}
}

/* Site close layout */
#site-layout {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 997;
	background: rgba(0, 0, 0, .8);

  @media (min-width: $mediaLg) {
    display: none;
  }
}

.disabled {
	pointer-events: none;
    opacity: 0.4;
}

.brd {
  &-acent {
    border-color: $colorAcent;
  }
  &-gray {
    border-color: #EFEFEF;
  }
  &-solid {
    border-style: solid;
  }
  &-1 {
    border-width: 1px;
  }
}
