.contacts-info {
    padding: 0;

    &__row {
        padding: 15px 0 0 0;
        position: relative;

        &--padding {
            padding-left: 40px;
        }
        &-icon {
            position: absolute;
            left: 0;
            top: 11px;
            width: 29px;
            height: 28px;
            overflow: hidden;

            &--address {
                background: url("../imgs/cms-sprite.png") no-repeat scroll -6px -235px transparent;
            }
            &--phone {
                background: url("../imgs/cms-sprite.png") no-repeat scroll -5px -316px transparent;
            }
        }
        &-title {
            display: block;
            width: 100%;
            margin-bottom: 5px;
            font-weight: 700;
            color: #666;
            letter-spacing: 0.5px;
            font-size: 14px;
            line-height: 18px;
        }
        &-text {
            display: inline-block;
            color: #666 !important;
            font-weight: 400;
            font-size: 14px;
            line-height: 18px;
            letter-spacing: 0.5px;
            text-decoration: none !important;
        }
    }
}
