.location {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  position: relative;
  padding-left: 15px;
  padding-right: 15px;

  &-confirm {
    display: none;
    position: absolute;
    background-color: #fff;
    color: #000;
    padding: 10px;
    flex-direction: column;
    z-index: 1;
    gap: 10px;

    &_open {
      display: flex;
    }

    &__text {
      text-wrap: nowrap;
    }

    &__actions {
      display: flex;
      gap: 10px;
    }

    &__button {
      text-wrap: nowrap;
    }
  }

  &-modal {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 1001;
    
    &__overlay {
      position: fixed;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
      background-color: rgba(#000, 0.8);
    }

    &__content {
      background-color: #efefef;
      padding: 25px;
      border-radius: 5px;
      width: 458px;
      height: 500px;
      z-index: 1;

      @media(max-width: $mediaMd) {
        height: 300px;
      }
    }

    &__search {
      position: relative;

      &-icon {
        left: 14px;
      }

      &-input {
        background-color: #fff;
        border-radius: 3px;
        padding: 12.5px 0 12.5px 44px;
        font-family: "Supermolot";
        font-size: 16px;
      }
    }

    &__list {
      margin-top: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: 5px;
      overflow-y: auto;
      height: 386px;
      -webkit-overflow-scrolling: touch;
      overscroll-behavior: contain;
      transform: translate3d(0,0,0);
      min-width: 50%;

      &-wrapper {
        display: flex;
        justify-content: space-between;
      }

      @media(max-width: $mediaMd) {
        &:first-child {
          width: 100%;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }


    &__location {
      color: $colorDarkGray;
      font-family: "Supermolot";
      font-size: 14px;
      text-align: left;

      &_is-bold {
        color: #000;
      }

      @media(max-width: $mediaMd) {
        color: #000;
        font-size: 18px;

        &_desktop-only {
          display: none;
        }
      }
      
      &:hover {
        color: $colorAcent;
      }
    }
  }
}
