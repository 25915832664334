.tabs {
  display: block;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  overflow: hidden;

  [class^="tab"] > label, [class*=" tab"] > label {
    color: #000;
    cursor: pointer;
    display: block;
    font-size: 1.1em;
    font-weight: 500;
    line-height: 1em;
    padding: 1.7rem 0 0.7rem 0;
    text-align: center;
  }

  [class^="tab"] [type="radio"], [class*=" tab"] [type="radio"] {
    border-bottom: 1px solid rgba(239, 237, 239, 0.5);
    border-radius: 0;
    cursor: pointer;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100%;
    -webkit-transition: all 0.1s ease-in-out;
    -moz-transition: all 0.1s ease-in-out;
    -o-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }

  [class^="tab"] [type="radio"] {
    &:hover, &:focus {
      border-bottom: 1px solid #fd264f;
    }
  }

  [class*=" tab"] [type="radio"] {
    &:hover, &:focus {
      border-bottom: 1px solid #fd264f;
    }
  }

  [class^="tab"] [type="radio"]:checked, [class*=" tab"] [type="radio"]:checked {
    border-bottom: 2px solid #fd264f;
  }

  [class^="tab"] [type="radio"]:checked + div, [class*=" tab"] [type="radio"]:checked + div {
    opacity: 1;
  }

  [class^="tab"] [type="radio"] + div, [class*=" tab"] [type="radio"] + div {
    display: block;
    opacity: 0;
    padding: 2rem 0;
    width: 90%;
    -webkit-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .tab-2 {
    width: 50%;

    [type="radio"] {
      + div {
        width: 200%;
        margin-left: 200%;
      }

      &:checked + div {
        margin-left: 0;
      }
    }

    &:last-child [type="radio"] {
      + div {
        margin-left: 100%;
      }

      &:checked + div {
        margin-left: -100%;
      }
    }
  }
}