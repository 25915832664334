@import 'swiper/css/bundle';

.swiper__dots {
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: 30px;
	@media (max-width: 768px) {
		margin-top: 20px;
	}
	span {
		width: 10px;
		height: 10px;
		border-radius: 100%;
		border: 1px solid #9e9e9e;

		+span {
			margin-left: 7px;
		}
	}

	.swiper-pagination-bullet-active {
		background-color: #9e9e9e;
	}
}
