.component_basket-product {
	.close-icon {
		cursor: pointer;
		position: absolute;
		right: 15px;
		top: 0;
		z-index: 1;
	}
	
	.img {
		height: 120px;
		width: 120px;
	}
	
	.count-wrap {
		height: 32px;
		width: 90px;
		
		input {
			text-align: center;
			width: 40%;
		}
		
		.switcher {
			height: 12px;
			width: 12px;
			
			svg {
				height: 100%;
				width: 100%;
			}
			
			&._minus {
				left: 10px;
			}
			
			&._plus {
				right: 10px;
			}
		}
	}

  .product-favorites-add {
    svg {
      height: 30px;
      width: 30px;
    }

    &._active {
      svg {
        fill: #ef7f00;
      }
    }
  }
	
	@media (max-width: $mediaXxl) {
		.img {
			height: 80px;
			width: 80px;
		}
	}
}
