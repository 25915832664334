#component_breadcrumbs {
	flex-wrap: wrap;
	li {
		a {
			color: inherit;
			&:hover {
				color: $colorText;
			}
		}
		
		&:not(:last-child) {
			&:after {
				content: "";
				    display: inline-block;
				    width: 5px;
				    height: 5px;
				    margin: 0 8px 0 2px;
				    border-left: 1px solid #888;
				    border-top: 1px solid #888;
				    vertical-align: middle;
				    -webkit-transform: rotate(135deg);
				    transform: rotate(135deg);
			}
		}
	}
}
