.component_service-small {
	min-height: 170px;
	overflow: hidden;
	padding: 30px 30px 30px 80px;
	
	.icon {
		height: 54px;
		left: 0;
		padding: 20px;
		position: absolute;
		top: 0;
		width: 54px;
	}
	
	@media (max-width: $mediaXxl) {
		min-height: 150px;
		padding: 15px 15px 15px 65px;
		
		.icon {
			height: 44px;
			padding: 14px;
			width: 44px;
		}
	}
	
	@media (max-width: $mediaXl) {
		.title {
			font-size: size(22);
		}
	}
}
