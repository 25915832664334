.page_search {
	.select-list {
		.select {
			border: 1px solid $colorGray;
			padding: 13px 40px 12px 20px;
		}
		
		.btn {
			padding: 11.5px 23px;
		}
	}
	
	.thumb {
		li {
			border-bottom: 1px solid $colorGray;
			border-top: 1px solid $colorGray;
			
			.dot {
				height: 8px;
				left: 0;
				width: 8px;
			}
		}
	}
	
	.disk-list {
		.item {
      .item-row {
        display: flex;
        width: 100%;
      }

      .nolink {
        background-color: white;
      }

      .link, .nolink {
        align-items: center;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
      }

      .link:nth-child(odd), .nolink:nth-child(odd) {
        flex: 0 0 41.2%;
        min-width: 120px;
      }

      .link:nth-child(even), .nolink:nth-child(even) {
        flex: 0 0 58.8%;
      }

			.radius {
				left: 0;
				padding: 1px 12px;
				top: 0 !important;
			}
			
			.link {
				background: #fff url("../imgs/icons/arrow-right.svg") calc(100% - 10px)/50% no-repeat;
				background-size: 7px auto;
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.section_not-found {
			padding-top: 0;
		}
	}
}
