.component_article {
	overflow: hidden;
	
	.icon {
		height: 54px;
		left: 0;
		padding: 20px;
		position: absolute;
		top: 0;
		width: 54px;
	}
	
	.content {
		height: 100%;
		padding: 20px 0 20px 80px;
	}
	
	img {
		border-radius: 3px;
	}
	
	@media (max-width: $mediaXxl) {
		.icon {
			height: 44px;
			padding: 12px;
			width: 44px;
		}
		
		.content {
			padding: 15px 0 15px 65px;
		}
	}
	
	@media (max-width: $mediaXl) {
		.content {
			padding: 15px;
		}
	}
	
	@media (max-width: $mediaMd) {
		.title {
			font-size: size(18);
		}
	}
}
