.from-to {
  display: flex;
  justify-content: space-between;

  &__wrap{
    border: 1px solid #f1f1f1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: size(10) size(10);
    height: size(40);
    min-width: size(120);
  }

  &__dropdown {
    width: 100%;
    text-align: left;
    position: relative;
    display: inline-block;

    &_up {
      .from-to {
        &__dropdown-content {
          bottom: 0;
        }
      }
    }

    &_open {
      .from-to {
        &__dropdown-content {
          display: block;
        }

        &__dropdown-button {
          display: none;
        }
      }
    }
  }

  &__dropdown-content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 100px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10000;
    max-height: 50vh;
    overflow-y: scroll;
    left: size(10);
  }

  &__dropdown-item {
    cursor: pointer;
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-weight: 700;

    &:hover {
      background-color: #a20202;
      color: #fff;
    }
  }

  &__dropdown-button {
    background-color: #fff;
    color: #000;
    padding: 0 21px;
    font-size: size(13);
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    text-align: left;

    &:after {
      content: "";
      right: 0;
      top: 40%;
      position: absolute;
      width: 14px;
      height: 10px;
      background-size: 13px 7px;
      background-image: url("../imgs/icons/select-arrow.svg");
      background-repeat: no-repeat;
    }
  }

  &__label {
    color: fade-out($colorDark, .4);
    display: block;
    font-size: size(11);
    font-weight: 700;
    text-transform: uppercase;
  }
}
