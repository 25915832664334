@import "../global/variables";

input,
select,
textarea,
button {
	font-family: $fontName;
}

input,
select,
textarea {
	width: 100%;
}

.select {
	background: #fff url("../imgs/icons/select-arrow.svg") calc(100% - 10px)/50% no-repeat;
	background-size: 12px auto;
	border-radius: 3px;
	font-size: size(13);
	font-weight: 700;
	line-height: 1;
	padding: 20px 25px 20px 15px;
	text-transform: uppercase;
}

.checkbox {
	border: 1px solid #efefef;
	border-radius: 3px;
	cursor: pointer;
	height: 18px;
	width: 18px;
  flex: 0 0 18px;
	
	&:checked {
		background: url("../imgs/icons/checkbox.svg") 50%/50% no-repeat;
		background-size: 14px auto;
	}
}

.form-label {
	display: block;
	
	span {
		color: fade-out($colorDark, .4);
		display: block;
		font-size: size(11);
		font-weight: 700;
		margin-bottom: 8px;
		text-transform: uppercase;
	}
	
	.select {
		border: 1px solid $colorGray;
		padding: 15px 40px 14px 20px;
	}
}

.form-field {
	border: 1px solid $colorGray;
	border-radius: 3px;
	display: block;
	position: relative;
	
	span {
		color: fade-out($colorDark, .5);
		font-size: size(12);
		left: 15px;
		position: absolute;
		top: 12px;
	}
	
	input,
	select {
		font-size: size(16);
		padding: 36px 15px 13px;
		line-height: 1.6;
	}
	
	select {
		background: url("../imgs/icons/select-arrow.svg") calc(100% - 10px) calc(100% - 22px) no-repeat;
		background-size: 10px auto;
	}
}

a.no-click {
	pointer-events: none;
}

@media (max-width: $mediaXxl) {
	.select {
		padding: 15px 25px 15px 10px;
	}
}
