@import "../global/variables";

.dropdown {
  width: 100%;
  text-align: left;
  position: relative;
  display: inline-block;

  &__button {
    background-color: #fff;
    color: #000;
    padding: 12px 21px;
    font-size: size(13);
    border: 1px solid #f1f1f1;
    border-radius: 3px;
    font-weight: bold;
    text-transform: uppercase;
    width: 100%;
    text-align: left;
    &:after {
      content: "";
      right: 11px;
      top: 40%;
      position: absolute;
      width: 14px;
      height: 10px;
      background-size: 13px 7px;
      background-image: url("../imgs/icons/select-arrow.svg");
      background-repeat: no-repeat;
    }
  }

  &__content {
    display: none;
    position: absolute;
    background-color: #fff;
    min-width: 160px;
    width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 10000;
    max-height: 45vh;
    overflow-y: scroll;
  }

  &_open {
    .dropdown {
      &__content {
        display: block;
      }
    }
  }

  &_up {
    .dropdown {
      &__content {
        bottom: 0;
      }
    }
  }

  &__item {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
    font-weight: 700;

    &:hover {
      background-color: #a20202;
      color: #fff;
    }
  }

  &:hover {
    .dropdown__button {
      background-color: #eee;
    }
  }
}
