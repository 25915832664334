#component_basket {
	.promo-code {
		.input-wrap {
			border: 1px solid $colorGray;
			
			input {
				padding: 12px 150px 12px 15px;
			}
			
			button {
				border-radius: 3px;
				color: fade-out($colorDark, .5);
				padding: 8px 15px;
				right: 5px;
			}
		}
	}
	
	@media (max-height: 600px) {
		overflow-y: auto;
		
		.inner-wrap {
			overflow: visible;
		}
	}
}
