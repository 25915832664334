.component_search {
	border-radius: 3px;
	overflow: hidden;
	padding: 30px;
	position: relative;
	
	.line {
		height: 2px;
		width: 100px;
	}
	
	.img {
		position: absolute;
		
		&._tire {
			right: -26px;
			top: -29px;
			width: 190px;
		}
		
		&._disk {
			right: -81px;
			top: -42px;
			width: 230px;
		}
	}
	
	.wrap {
		.num {
			font-size: size(13);
			font-weight: 700;
			height: 30px;
			left: 13px;
			line-height: 30px;
			text-align: center;
			width: 18px;
		}
		
		.select {
			padding-left: 44px;
		}
	}
	
	@media (max-width: $mediaXxl) {
		padding: 15px;
		
		.img {
			&._disk {
				right: -130px;
			}
			
			&._tire {
				right: -90px;
			}
		}
		
		.wrap {
			.num {
				left: 10px;
			}
			
			.select {
				padding-left: 40px;
			}
		}
	}
}
