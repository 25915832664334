.vs_widget {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    font-family: 'SF', 'Open Sans', sans-serif;
    font-weight: 400;
    color: #22272a;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.5px;
    box-sizing: border-box;
    position: fixed;
    right: 50px;
    bottom: 80px;
    z-index: 2;   

    .vs_widget_button {
        width: 60px;
        height: 60px;
        background-color: #57d163;
        float: right;
        display: block;
        background-size: cover;
        position: relative;
        z-index: 5;
        cursor: pointer;
        border-radius: 50%;
        user-select: none;
        -moz-user-select: none;
        display: flex;
        align-items: center;
        justify-content: center;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
    }
    .vs_widget_button_svg{
        display: block;
        width: 100%;
        height: 100%;
        z-index: 6;
        background-size: 80%;
        background-image: url(../imgs/vs_widget/button.svg);
        background-repeat: no-repeat;
        background-position: 50%;
    }
    .vs_widget_button:before {
        animation-delay: 0s;
    }
    .vs_widget_button:before,.vs_widget_button:after {
        content: "";
        background-color: inherit;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        z-index: 0;
        animation: vs_widget_button-wave infinite 2.6s 0s linear;
    }
    .vs_widget_button:after {
        animation-delay: 1.3s;
    }
    .vs_widget_submenu{
        list-style-type: none;
        padding: 0;
        margin: 0;
        position: absolute;
        bottom: 70px;
        left: 5px;
    }
    .vs_widget_submenu li{
        transition: all ease-in-out 0.1s;
        z-index: -1;
        opacity: 0;
        margin-bottom: 3px;
    }
    .vs_widget_submenu.vs_widget_hidden li{
            transform: translate( 0, 60px);
    }
    .vs_widget_submenu.active li:nth-child(1) {
        opacity: 1;
        transition-delay: 0.025s;
        transform: rotateY(0deg);
    }
    .vs_widget_submenu.active li:nth-child(2) {
        opacity: 1;
        transition-delay: 0.05s;
        transform: rotateY(0deg);
    }
    .vs_widget_submenu.active li:nth-child(3) {
        opacity: 1;
        transition-delay: 0.075s;
        transform: rotateY(0deg);
    }
    .vs_widget_submenu.active li:nth-child(4) {
        opacity: 1;
        transition-delay: 0.01s;
        transform: rotateY(0deg);
    }
    .vs_widget_submenu.active li:nth-child(5) {
        opacity: 1;
        transition-delay: 0.0125s;
        transform: rotateY(0deg);
    }
    .active {

        .whatsapp {
            background: #57d163 url(../imgs/vs_widget/whatsapp.svg) 50% no-repeat;
            transition-duration: 100ms;
        }
        .vk {
            background: #4a76a8 url(../imgs/vs_widget/vk.svg) 50% no-repeat;
            transition-duration: 200ms;
        }
        .viber {
            background: #665cac url(../imgs/vs_widget/viber.svg) 50% no-repeat;
            transition-duration: 300ms;
        }
        .telegram {
            background: #2ca8dd url(../imgs/vs_widget/telegram.svg) 50% no-repeat;
            transition-duration: 400ms;
        }
        .question {
            background: #a20202;
            color: #fff;
            font-size: 9px;
            text-align: center;
            line-height: 12px;
            padding-top: 13px;
            transition-duration: 400ms;
        }
    }
    .whatsapp {
        -webkit-transform: translate( 0, 60px);
        transform: translate( 0, 60px);
    }
    .vk {
        -webkit-transform: translate( 0, 120px);
        transform: translate( 0, 120px);
    }
    .viber {
        -webkit-transform: translate( 0, 180px);
        transform: translate( 0, 180px);
    }
    .telegram {
        -webkit-transform: translate( 0, 240px);
        transform: translate( 0, 240px);
    }
    .question {
        -webkit-transform: translate( 0, 240px);
        transform: translate( 0, 240px);
    }
    .vs_widget_submenu.active li a{
        display: block;
        width: 50px;
        height: 50px;
        background-size: 80%;
        position: relative;
        z-index: 5;
        cursor: pointer;
        border-radius: 60px;
        visibility: visible;
        opacity: 1;
        -webkit-filter: blur(0);
        filter: blur(0);
        will-change: transform, opacity;
        -webkit-transform: translate(0, 0);
        transform: translate(0, 0);
        -webkit-transition: all 0.2s ease-out 0s;
        transition: all 0.2s ease-out 0s;
    }

    @media screen and (min-width: 450px) {
      .vs_widget_submenu li a.viber, .vs_widget_submenu li a.telegram{
        display:none;
      }
    }
}

@keyframes vs_widget_button-wave {
  0% {
      transform: scale(1, 1);
      opacity: 0.45
  }
  40% {
      opacity: 0.45
  }
  100% {
      transform: scale(1.35, 1.35);
      opacity: 0
  }
}