.page_contacts {
	#map {
		height: 400px;
		overflow: hidden;
		
		.gm-style-iw {
			padding: 0 !important;
			border-radius: 3px;
			
			.gm-ui-hover-effect {
				top: 0 !important;
				right: 0 !important;
				width: 12px !important;
				height: 12px !important;
				background: #fff !important;
				opacity: 1;
				
				img {
					width: 100% !important;
					height: 100% !important;
					margin: 0 !important;
				}
			}
			
			&-d {
				overflow: visible !important;
			}
			
			&-t {
				&:after {
					background: $colorDark !important;
				}
			}
		}
		
		.info-window {
			width: 208px;
			
			.line {
				padding: 13px 15px;
				border-bottom: 1px solid #3b3b3b;
				
				.bg-acent {
					height: 2px;
				}
			}
			
			ul {
				padding: 15px;
				
				li {
					&:not(:last-child) {
						margin-bottom: 8px;
					}
				}
			}
		}
	}
	
	.search-wrap {
		.icon {
			left: 14px;
		}
		
		input {
			border: 2px solid #e8eaed;
			border-radius: 3px;
			font-size: size(14);
			font-weight: 600;
			padding: 9px 15px 9px 43px;
			width: 100%;
		}
	}
	
	.list {
		max-height: 427px;
		
		&-item {
			background: #fff;
			cursor: pointer;
			height: 100%;
			
			.city {
				color: fade-out($colorDark, .3);
			}
			
			.icon {
				background: $colorGray;
				border-radius: 3px;
				height: 40px;
				padding: 6px;
				width: 24px;
			}
			
			&._active {
				box-shadow: inset 0 0 0 1px $colorAcent;
				
				.city {
					color: $colorDark;
				}
				
				.icon {
					background: $colorAcent;
					fill: #fff;
				}
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		#map {
			height: 400px;
		}
		
		.list {
			max-height: 327px;
		}
	}
	
	@media (max-width: $mediaLg) {
		#map {
			height: 260px;
		}
		
		.list {
			max-height: none;
		}
	}
	
	@media (max-width: $media475) {
		#map {
			height: 200px;
		}
	}
}
