.expandable {
  border: 1px solid $colorAcent;
  border-radius: 3px;
  height: 40px;
  overflow: hidden;
  display: inline-block;
  position: absolute;
  background-color: #FFFFFF;
  // min-width: 160px;
  width: calc(100% - 2rem);
  z-index: 10;

  @media(max-width: $mediaSm) {
    display: block;
  }
  
  &__button {
    height: 40px;
    position: relative;
    padding: 10px;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    text-transform: uppercase;
    cursor: pointer;

    &:after {
      content: "";
      right: 11px;
      top: 14px;
      position: absolute;
      width: 14px;
      height: 10px;
      background-size: 13px 7px;
      background-image: url("../imgs/icons/select-arrow.svg");
      background-repeat: no-repeat;
    }
  }

  &__content {
    padding: 10px;
  }

  &_open {
    height: auto;
  }
}
