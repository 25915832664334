.fslightbox-source {
	background-color: #fff;
}

.product-data__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  svg {
    height: 30px;
    aspect-ratio: 1;
  }
}

.page_product {
  h1 {
    font-family: OpenSans;
  }

	.product-data {
		.btn-wrap {
			display: flex;
			.row {
				height: 100%;
			}
		}
    .underline {
      text-decoration: underline;
    }
		&__icon {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 100%;
			svg {
				height: 30px;
				aspect-ratio: 1;
			}
		}
		.image-wrap {
			height: 430px;
			padding-left: 100px;
			
			.thumb-imgs {
				bottom: 0;
				left: 0;
				position: absolute;
				top: 0;
				padding-right: 5px;
				transform: scale(-1,1);
				&::-webkit-scrollbar-track {
					-webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);
					background-color: #F5F5F5;
				}
				&::-webkit-scrollbar {
					width: 3px;
					background-color: #F5F5F5;
				}
				&::-webkit-scrollbar-thumb {
					background-color: #a20202;
				}
				img {
					height: 74px;
					padding: 6px;
					width: 74px;
					flex-shrink: 0;
					transform: scale(-1,1);
					&._active {
						border: 1px solid $colorAcent;
					}
				}
			}
			
			.main-img {
				padding: 20px;
				//height: 100%;
				
				a {
					outline: none !important;
				}
			}
			
			.js-image-zoom__zoomed-image {
				background: #fff;
			}
		}
		
		.char-list {
			li {
				span {
					border-top: 1px solid $colorGray;
					padding: 9px 0;
					width: 48%;
				}
			}
		}
		
		.btn-wrap {
			.item {
				height: 64px;
			}
		}
		
		.count-wrap {
			padding: 12px;
			width: 100px;
			
			.switcher {
				width: 20px;
				
				svg {
					height: 100%;
					padding: 4px;
					width: 100%;
				}
			}
			
			input {
				width: 28px;
			}
		}
		
		.add-to-cart {
			height: 100%;
			
			svg {
				height: 24px;
				width: 24px;
			}
		}
		
		.thumb-btn {
			
			
			&-1 {
				padding: 12px;
			}
		}
		
		.product-favorites-add {
			&._active {
				svg {
					fill: #ef7f00;
				}
			}
		}
		
		.tabs {
			&-header {
				.tab {
					border-bottom: 2px solid $colorGray;
					cursor: pointer;
					
					&._active {
						border-color: $colorAcent;
					}
				}
			}
			
			&-content {
				display: none;
				
				ul {
					font-size: size(12);
					
					li {
						margin-bottom: 13px;
						
						b {
							font-weight: 700;
						}
						
						.icon {
							border-radius: 3px;
							height: 34px;
							padding: 7px;
							width: 34px;
						}
					}
				}
				
				&._visible {
					display: block;
				}
			}
		}
	}
	
	.line {
		background: #e6e5e5;
		height: 2px;
	}
	
	.slider-wrap {
		.swiper {
			&-button {
				height: 48px;
				width: 48px;
				
				svg {
					fill: #dbdbdb;
					height: 100%;
					width: 100%;
				}
				
				&-prev {
					left: -60px;
				}
				
				&-next {
					right: -60px;
				}
			}
		}
	}
	
	.manager {
		.avatar {
			border-radius: 100%;
			height: 66px;
			width: 66px;
		}
		
		.button {
			border: 1px solid $colorAcent;
			padding: 15px 28px;
			&:hover {
				background: $colorAcent;
				color: #fff;
			}
		}
	}

	.ask-q {
		display: block;
		width: 100%;
		border-radius: 3px;
		border: 1px solid $colorAcent;
		text-transform: uppercase;
		padding: 10px 20px;
		color: $colorAcent;
		text-align: center;
		font-size: 14px;
		font-weight: 700;
	}

	.gallery-mobile {
		width: 100%;
	}
	
	@media (max-width: $mediaMd) {
		.product-data {
			.image-wrap {
				padding-left: 70px;
				
				.thumb-imgs {
					img {
						height: 50px;
						padding: 4px;
						width: 50px;
					}
				}
			}
			
			.btn-wrap {
				.item {
					height: 44px;
				}
			}
		}
		
		.des-text {
			font-size: size(13);
		}
	}
	
	@media (max-width: $media475) {
		.product-data {
			.add-to-cart {
				svg {
					width: 20px;
					height: 20px;
				}
			}
			
			.image-wrap {
				height: 340px;
			}
		}
	}
	
	@media (max-width: $media375) {
		.product-data {
			.image-wrap {
				height: 280px;
			}
		}
	}
}

.color-variants {
  margin: 10px 0;
  position: relative;
  display: flex;
  align-items: center;

  svg, img {
    width: 50px;
    height: 50px;
  }
  
  &__link {
    /* z-index: 1; */
    position: relative;
    margin-left: 5px;
  }

  &_chosen {
    // z-index: 0;
    // top: -1px;
    // left: -1px;
    // width: 26px;
    // height: 26px;
    border: 2px solid $colorAcent;
    border-radius: 26px;
    // position: absolute;
  }

  @media(max-width: $mediaSm) {
    justify-content: center;
  }
}
