/* Font size */
$fontSize: 16;
$fontName: "OpenSans", Arial, sans-serif;

/* Colors */
// $colorAcent: #a20202;
$colorAcent: #e4002b;
$colorAcentHover: #D13636;
$colorGray: #f1f1f1;
$colorDarkGray: #aaa;
$colorDark: #0f0f0f;
$colorText: #0f0f0f;
$colorTextFade: #535353;
$colorOrange: #FD691F;
$colorBlue: #01A9EE;

/* Functions */
@function size($target, $context: $fontSize) {
	@return ($target / $context) * 1rem;
}

@mixin text-overflow($lines) {
	overflow: hidden;
	text-overflow: ellipsis;
	display: -webkit-box !important;
	-webkit-line-clamp: $lines;
	-webkit-box-orient: vertical;
}

@mixin placeholder($color) {
	&::-webkit-input-placeholder,
	&::-moz-placeholder,
	&:-moz-placeholder,
	&:-ms-input-placeholder {
		color: $color;
	}
}

/* Media */
$mediaXxl: 1569.98px;
$mediaXl: 1299.98px;
$mediaLg: 991.98px;
$mediaMd: 767.98px;
$mediaSm: 575.98px;
$media475: 475.98px;
$media375: 375.98px;
$media320: 320.98px;
