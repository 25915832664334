.page_about {
	p {
		padding-bottom: 1.5rem;
		font-size: 18px;
	}
}
.a-banner {
	color: #fff;

  &__columns {
    display: flex;
    justify-content: space-between;
    width: 100%;
		padding: 40px 50px 44px;
    z-index: 2;
  }

	&__wrapper {
		position: relative;
		// padding: 40px 50px 44px;
		display: flex;
		justify-content: space-between;
		border-radius: 4px;
		overflow: hidden;
	}

  &__img {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 57% 30%;
  }

	&__left {
		max-width: 475px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__sub-title {
		font-weight: 700;
		font-size: 32px;
		line-height: 120%;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		padding-bottom: 24px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: -50px;
			bottom: 0;
			right: 40%;
			height: 1px;
			background-color: #fff;
		}
	}

	&__title {
		font-size: 54px;
		line-height: 120%;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		margin-top: 22px;
	}

	&__desc {
		margin: 30px 0 0;
	}

	&__right {
		width: 340px;
	}

	&__item {
		+div {
			margin-top: 42px;
		}
	}

	&__item-icon {
		width: 64px;
		height: 64px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
    stroke: #fff;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__item-title {
		margin-top: 16px;
		font-weight: 700;
		font-size: 32px;
		line-height: 1;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		/* color: #FFFFFF; */
		position: relative;
		padding-bottom: 16px;
		margin-bottom: 15px;

		&::after {
			content: '';
			left: 12%;
			right: 0;
			bottom: 0;
			height: 1px;
			background-color: #fff;
			position: absolute;
		}
	}

	&__item-desc {}
}

.s-title {
	font-weight: 700;
	font-size: 32px;
	letter-spacing: 0.02em;
	text-transform: uppercase;
	color: #000000;
	margin: 0;
}

.title-line {
	padding-bottom: 25px;
	position: relative;

	&::before {
		content: '';
		position: absolute;
		left: -50px;
		bottom: 0;
		width: 195px;
		height: 1px;
		background: $colorAcent;
	}
}

.a-idea {
	margin-top: 100px;

	&__info-wrapper {}

	&__row {
		display: flex;
		gap: 20px;

		+div {
			margin-top: 110px;
		}

		@media (min-width: 992px) {
			&:nth-child(2n) {
				flex-direction: row-reverse;

				.a-idea__images {
					align-items: flex-end;
				}
			}

			.a-idea__info {
				padding-left: 0;
			}

		}
	}

	&__info {
		flex: 1;
		padding-left: 50px;

		p {
			&:last-child {
				margin-bottom: 0;
			}
		}

		.s-title {
			margin-bottom: 30px;
		}
	}

	&__images {
		width: 725px;
		display: flex;
		flex-direction: column;

		img {
			max-width: 100%;
			height: auto;
			max-height: 250px;
			object-fit: cover;

			&:nth-child(2) {
				max-width: 440px;
			}

			+img {
				margin-top: 22px;
			}
		}
	}
}

.read-more {
	display: flex;
	height: 56px;
	width: 100%;
	padding: 0;
	border: none;
	background-color: transparent;

	span {
		flex: 1;
		background: #F1F1F1;
		border-radius: 4px;
		display: flex;
		align-items: center;
		text-align: left;
		height: 100%;
		padding: 5px 24px;
		font-weight: 700;
		font-size: 12px;
		line-height: 1;
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}

	&__icon {
		width: 56px;
		margin-left: 2px;
		background: #F1F1F1;
		border-radius: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
	}
}

.a-offer {
	margin-top: 50px;

	&__wrapper {
		display: flex;
		gap: 50px;
		margin-top: 34px;
		counter-reset: num;
	}

	&__item {
		counter-increment: num;
		width: 100%;

		&:nth-child(-n+9) {
			.a-offer__item-top {
				&::after {
					content: "0"counter(num);
				}
			}
		}
	}

	&__item-top {
		position: relative;
		padding-bottom: 19px;

		&::before {
			content: '';
			position: absolute;
			left: 30px;
			bottom: 0;
			right: 0;
			height: 1px;
			background: #E4192C;
		}

		&::after {
			content: counter(num);
			font-weight: 800;
			font-size: 60px;
			line-height: 1;
			color: #ECECEC;
			position: absolute;
			right: 0;
			bottom: -5px;
		}

		img {
			height: 60px;
		}
	}

	&__item-title {
		font-weight: 700;
		font-size: 20px;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: $colorAcent;
		margin-top: 20px;
	}

	&__item-desc {
		margin: 16px 0 0;
		font-size: 16px;
		letter-spacing: 0.02em;
		color: #A3A3A3;
	}
}

.certificates {
	margin-top: 100px;
	&__wrapper {
		margin-top: 30px;
	}

	&__slider {
		position: relative;
	}

	&__prev,
	&__next {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		background: #F1F1F1;
		border-radius: 4px;
		width: 56px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__prev {
		left: -72px;
	}

	&__init {
		overflow: hidden;
	}

	&__item {}

	&__item-image {
		border: 1px solid #EAEAEA;
		border-radius: 4px;
		height: 180px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 4px;

		img {
			max-width: 100%;
			max-height: 100%;
			border-radius: 4px;
		}
	}

	&__item-title {
		margin-top: 11px;
		letter-spacing: 0.02em;
		font-size: 12px;

		b {
			font-weight: 700;
			color: $colorAcent;

		}
	}

	&__next {
		right: -72px;
	}
}

.s-title+.s-desc {
	margin-top: 17px;
}

.s-desc {
	letter-spacing: 0.02em;
	font-size: 16px;
	color: #A3A3A3;
	margin: 0;
}

.a-gallery {
	margin-top: 100px;

	&__prev,
	&__next {
		position: absolute;
		top: 0;
		bottom: 0;
		margin: auto;
		background: #F1F1F1;
		border-radius: 4px;
		width: 56px;
		height: 56px;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__prev {
		left: -72px;
	}

	&__next {
		right: -72px;
	}

	&__wrapper {
		position: relative;
		margin-top: 35px;

		.swiper-slide {
			img {
				width: 100%;
				/* height: 325px; */
				object-fit: cover;
			}
		}
	}

	&__prev {}

	&__next {}

	&__slider-init {
		overflow: hidden;
	}

	&__dots {
		margin-top: 18px;

		font-size: 0;
		display: flex;
		align-items: center;
		justify-content: center;

		.swiper-pagination-bullet {
			background: #CDCDCD;
			opacity: 1;
			border-radius: 50%;
		}

		.swiper-pagination-bullet-active {
			width: 14px;
			height: 14px;
			border: 1px solid $colorAcent;
			background-color: transparent;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		.swiper-pagination-bullet-active::before {
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: #000;
		}
	}
}

.swiper-slide,
.swiper-slide a {
	outline: none;
}

.a-about {
	margin-top: 120px;

	&__wrapper {
		display: flex;
	}

	&__slider {
		width: 725px;
		flex-shrink: 0;
		margin-right: 60px;
	}

	&__slider-init {
		.swiper-slide {
			border-radius: 4px;
			overflow: hidden;
      aspect-ratio: 4 / 3;

			img {
				/* height: 430px; */
        height: 100%;
				width: 100%;
				object-fit: cover;
			}
		}
	}

	&__slider-nav {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		margin-top: 14px;
		z-index: 2;
	}

	&__slider-nav--dots {
		position: absolute;
		left: 0;
		right: 0;
		bottom: auto !important;

		display: flex;
		align-items: center;
		justify-content: center;

		.swiper-pagination-bullet {
			background: #CDCDCD;
			opacity: 1;
			border-radius: 50%;
		}

		.swiper-pagination-bullet-active {
			width: 14px;
			height: 14px;
			border: 1px solid $colorAcent;
			background-color: transparent;
			position: relative;
			display: inline-flex;
			align-items: center;
			justify-content: center;
		}

		.swiper-pagination-bullet-active::before {
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			border-radius: 100%;
			background-color: #000;
		}
	}

	&__slider-nav--arrows {
		flex-shrink: 0;
		display: flex;
		margin-left: auto;
		position: relative;
		z-index: 5;
	}

	&__slider-nav--prev,
	&__slider-nav--next {
		width: 56px;
		height: 56px;
		display: inline-flex;
		align-items: center;
		justify-content: center;
		border-radius: 4px;
	}

	&__slider-nav--prev {
		background: #F1F1F1;
	}

	&__slider-nav--next {
		background: $colorAcent;
		margin-left: 4px;
	}

	&__info {
		flex: 1;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	.s-title {
		margin-bottom: 30px;
	}

	.btn-link {
		margin-top: 15px;
	}
}

.swiper-button-lock {
	display: none !important;
}

.btn-link {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	font-size: 12px;
	line-height: 120%;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	color: #000000;
	font-weight: bold;
	text-decoration: none;
	border: 1px solid $colorAcent;
	border-radius: 4px;
	height: 56px;
	padding: 0 35px;
	transition-duration: .15s;
}

.btn-link:hover {
	background-color: $colorAcent;
	color: #fff;
}

.a-requisites {
	margin-top: 100px;
	&__wrapper {
		margin-top: 30px;

		table {
			width: 100%;

			td {
				border-top: 1px solid #D9D9D9;
				border-bottom: 1px solid #D9D9D9;
				padding: 10px 0;
				font-size: 12px;
				line-height: 120%;
				letter-spacing: 0.04em;
				text-transform: uppercase;

				&:last-child {
					font-weight: bold;
				}

				&:first-child {
					position: relative;
					padding-right: 15px;
          width: 250px;
					&::after {
						content: '';
						position: absolute;
						right: 0;
						top: -1px;
						bottom: -1px;
						width: 12px;
						background-color: #fff;
					}
				}
			}
		}
	}

	a.btn-link {
		margin-top: 35px;
	}
}

.faq {
	margin-top: 100px;

	&__wrapper {
		margin-top: 30px;
	}

	&__item {
		border: 1px solid #ECECEC;
		border-radius: 4px;
		padding: 0 70px 0 25px;
		cursor: pointer;
		position: relative;
		+ div {
			margin-top: 15px;
		}
		&.opened {
			.faq__item-icon {
				background-color: transparent;
				transform: scale(1,-1);
			}
		}
	}

	&__item-content {
	
		p {
			&:last-child {
				margin-bottom: 0;
			}
		}
	}

	&__item-title {
		font-weight: 700;
		font-size: 14px;
		line-height: 120%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
		padding: 20px 0;
		min-height: 62px;
		display: flex;
		align-items: center;
	}

	&__item-desc {
		color: #A3A3A3;
		letter-spacing: 0.02em;
		display: none;
		font-size: 16px;
	}

	&__item-icon {
		position: absolute;
		right: 9px;
		top: 9px;
		bottom: 9px;
		width: 44px;
		background: #F1F1F1;
		border-radius: 2px;
		display: flex;
		align-items: center;
		justify-content: center;
		transition-duration: .15s;
	}
}

/* === DESKTOP FIRST === */
@media (max-width : 1600px) {

	.a-gallery__wrapper,
	.certificates__slider {
		width: calc(100% - 130px);
		margin-left: auto;
		margin-right: auto;
	}
}

/* xl <= Extra extra large (xxl) */
@media (max-width: $mediaXxl) {
	/* .container { */
	/* 	padding-left: 10px; */
	/* 	padding-right: 10px; */
	/* 	width: 1240px; */
	/* } */

	.a-about__slider,
	.a-idea__images {
		width: 580px;
	}

	/* .a-about__slider-init .swiper-slide img { */
	/* 	height: 360px; */
	/* } */

	/* .a-gallery__wrapper .swiper-slide img { */
	/* 	height: 280px; */
	/* } */
}

/* lg <= Extra large (xl) */
@media (max-width: $mediaXl) {
	.a-banner__title {
		font-size: 44px;
	}

	.a-banner__item-title {
		font-size: 24px;
	}

  .a-banner__left {
    max-width: 250px;
  }

	.a-banner__right {
		width: 250px;
	}

	.a-banner__sub-title {
		font-size: 26px;
	}

	.a-banner__item-icon {
		width: 50px;
		height: 50px;
	}

	.a-about__slider,
	.a-idea__images {
		width: 440px;
	}

	/* .a-about__slider-init .swiper-slide img { */
	/* 	height: 280px; */
	/* } */
}

/* md <= Large (lg) */
@media (max-width : $mediaLg) {
	.s-title {
		font-size: 28px;
	}

	.a-idea__row {
		flex-direction: column;
		gap: 0;
	}

	.a-idea__images {
		margin: 30px auto 0;
		align-items: center;
		justify-content: center;
	}

	.a-idea__row+div {
		margin-top: 50px;
	}

  .a-banner__img {
    position: relative;
    height: auto;
    aspect-ratio: 4 / 3;
    border-radius: 0 0 4px 4px;
  }

	.a-banner__wrapper {
		/* padding: 30px; */
		flex-direction: column;
    color: $colorDark;
	}

  .a-banner__columns {
    flex-direction: column;
    padding-right: 0;
    padding-left: 0;
  }

	.a-banner__left {
		max-width: 100%;
	}

	.a-banner__right {
		width: 100%;
		display: flex;
		margin-top: 30px;
	}

	.a-banner__item+div {
		margin: 0 0 0 30px;
	}

  .a-banner__item-icon {
    stroke: $colorDark;
  }

	/* .a-banner__wrapper::before { */
	/* 	content: ''; */
	/* 	position: absolute; */
	/* 	left: 0; */
	/* 	right: 0; */
	/* 	top: 0; */
	/* 	bottom: 0; */
	/* 	background-color: rgba(0, 0, 0, .35); */
	/* 	z-index: 1; */
	/* } */

	.a-idea,
	.a-gallery,
	.a-about,
	.faq,
	.certificates,
	.a-requisites {
		margin-top: 60px;
	}

  .a-banner__sub-title:before {
    background-color: $colorDark;
  }

	.a-banner__item-title {
		white-space: nowrap;
    
    &:after {
      background-color: $colorDark;
    }
	}

	.a-offer__wrapper {
		display: grid;
		gap: 20px;
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}

	.a-about__wrapper {
		flex-direction: column-reverse;
	}

	.a-about__slider {
		margin: 30px auto 0;
		width: 100%;
		max-width: 500px;
	}
	.a-offer__item-title {
		font-size: 18px;
	}
	.a-offer__item-desc {
		font-size: 14px;
	}
}

/* sm <= Medium (md) */
@media (max-width : 768px) {
	.container {
		width: 100%;
	}

	.a-idea,
	.a-gallery,
	.a-about,
	.faq,
	.certificates,
	.a-requisites {
		margin-top: 40px;
	}

	.a-banner__title {
		font-size: 36px;
	}

	.a-idea__info {
		padding-left: 0;
	}

	.title-line::before {
		left: 0;
	}

	.a-about__slider,
	.a-idea__images {
		width: 100%;
	}

	.a-idea__images img:nth-child(2) {
		max-width: 100%;
	}

	.certificates__item-image {
		height: 120px;
	}

	.certificates__prev,
	.certificates__next,
	.a-gallery__prev,
	.a-gallery__next {
		width: 44px;
		height: 44px;
	}

	.certificates__prev,
	.a-gallery__prev {
		left: -55px;
	}

	.certificates__next,
	.a-gallery__next {
		right: -55px;
	}

	.a-gallery__wrapper,
	.certificates__slider {
		width: calc(100% - 100px);
	}

	.a-gallery__wrapper .swiper-slide,
	.a-about__slider-init .swiper-slide {
		position: relative;
		padding-top: 50%;
	}

	.a-gallery__wrapper .swiper-slide img,
	.a-about__slider-init .swiper-slide img {
		position: absolute;
		left: 0;
		top: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		object-fit: cover;
	}
	.faq__item-title {
		padding: 15px 0;
	}
	.faq__item {
		padding-left: 15px;
	}
	:root {
		--regular-text: 16px;
	}
	.faq__item-desc {
		font-size: 14px;
	}
	.a-about__slider-nav--prev, .a-about__slider-nav--next {
		width: 44px;
		height: 44px;
	}
	.a-banner__item-icon {
		width: 40px;
		height: 40px;
	}
	.a-banner__item-title {
		font-size: 20px;
		padding-bottom: 10px;
	}
	.a-offer__item-top img {
		height: 40px;
	}
	.a-offer__item-top::after {
		font-size: 40px;
		bottom: 0;
	}
	.a-offer__item-top {
		padding-bottom: 5px;
	}
	.a-offer__item-desc {
		margin-top: 10px;
	}
}

/* xs <= Small (sm) */
@media (max-width : 576px) {
	.a-banner__right {
		flex-direction: column;
	}

	.a-banner__item+div {
		margin: 15px 0 0;
	}

	.a-banner__item-title {
		margin: 10px 0;
	}

	.a-banner__desc {
		margin: 20px 0 0;
	}

	.a-banner__sub-title {
		font-size: 20px;
	}

	.a-banner__title {
		font-size: 28px;
	}

	.s-title {
		font-size: 24px;
	}
	
}


/* === MOBILE FIRST === */

/* Custom (xs) */
@media (min-width : 0) {}

/* Small (sm) */
@media (min-width : 576px) {}

/* Medium (md) */
@media (min-width : 768px) {}

/* Large (lg) */
@media (min-width : 992px) {}

/* Extra large (xl) */
@media (min-width : 1200px) {
	.text {
		font-size: 18px;
	}
}

/* Extra extra large (xxl) */
@media (min-width : 1400px) {
	.text {
		font-size: 22px;
	}
}
