@import "./from-to";

.catalog-filter-form {
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

	.nouislider-wrap {
		// border: 1px solid $colorGray;
		// border-radius: 3px;
		height: size(44);
		
		span {
			margin-bottom: 0 !important;
		}
		
		.value {
			color: $colorDark;
			font-size: size(15) !important;
      font-weight: 700;
      max-width: size(60);
      // margin-left: size(10);
      text-align: right;
		}
		
		.from {
      line-height: size(15);
			// left: 15px;
      left: 0;
      padding: 10px 10px;
      border: 1px solid $colorGray;
      border-radius: 3px;
		}
		
		.to {
      line-height: size(15);
			// right: 15px;
      right: 0;
      padding: 10px 10px;
      border: 1px solid $colorGray;
      border-radius: 3px;
		}
		
		.noUi {
			&-target {
				background: $colorGray;
				border: none !important;
				border-radius: 2px;
				bottom: -15px;
				height: 6px;
				left: 0;
				position: absolute;
				right: 0;
			}
			
			&-connect {
				background: $colorAcent;
			}
			
			&-handle {
				cursor: pointer;
				background: $colorDark;
				border: none !important;
				border-radius: 3px;
				box-shadow: unset !important;
				height: 20px;
				right: -10px;
				top: -7px;
				width: 20px;
				
				&:before,
				&:after {
					display: none;
				}
			}
		}
	}
	
	.char-list {
		label {
			color: $colorTextFade;
		}
	}

  a._expand {
    margin-top: 10px;
    display: inline-block;
  }

  ._collapsed {
    max-height: 200px;
    overflow: hidden;

    &._expanded {
      max-height: none;
    }
  }


}
