.inline-svg-icon {
	position: absolute;
    left: 0;
    width: 50px;
    height: 50px;
}
.product-data {
  .image-wrap:not(.p-0) {
    .badge-cont {
      width: calc(100% - 100px);
      left: auto;
    }
  }
}

.promo-filter {
  background-color: $colorAcent;
  color: #fff;
  padding: 10px;
  border-radius: 3px;
  
  .checkbox {
    background-color: #fff;
  }
}

.promo-market-price {
  font-family: Supermolot;
  font-size: 14px;
  text-decoration-line: line-through;
  text-transform: none;
}

.promo-prices {
  display: flex;
  align-items: center;
  gap: 10px;
}

@keyframes promo-slide {
  0%,100% {
    margin-top: 0;
  }
  30% {
    margin-top: 0;
  }
  50% {
    margin-top: -30px;
  }
  80% {
    margin-top: -30px;
  }
}

.promo-badge {
  color: #fff;
  display: flex;
  position: relative;
  margin-top: 2px;
  // gap: 10px;
  // background-color: $colorAcent;

  &__carousel {
    position: relative;
    width: 100%;
    height: 30px;
    animation: promo-slide 5s infinite;

    &-wrapper {
      overflow: hidden;
    }
  }

  &__slide {
    display: flex;
    align-items: center;

  }

  & > * {
    background-color: $colorAcent;
    height: 30px !important;
  }

  &__title {
    font-family: Supermolot;
    line-height: 30px;
    font-size: 12px;
    padding: 0 10px;
    font-weight: normal;
    text-transform: none;
  }

  &__icon {
    width: 30px;
    height: 30px
  }

  &:before {
    content: "";
    border-top: size(30) solid transparent;
    border-right: size(7) solid $colorAcent;
  }

  &:after {
    content: "";
    border-bottom: size(30) solid transparent;
    border-left: size(7) solid $colorAcent;
  }
}

.badge-cont {
  position: absolute;
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  top: 0;
  left: 0;

  .promo-badge {
    &__title {
      @media(max-width: $mediaSm) {
        padding: 0;
        font-size: 8px;
      }
    }
  }
}

.badge-group {
	display: flex;
	flex-direction: column;
	position: relative;
	align-items: flex-start;
  width: 50%;
  flex-shrink: 0;
  padding-left: 5px;
  overflow: visible;
  z-index: 1000;

  &:last-child {
    align-items: flex-end;
    z-index: 1;

    .badge-wrap {
      flex-direction: row-reverse;
      .badge-title {
        right: 20%;
        position: absolute;
        left: auto;
      }
    }
  }

  .badge-wrap {
    padding: 2px;
    display: flex;
    transition: all .3s;
    
    --active-fill-color: black;

    &.fill-acent {
      --active-fill-color: #{$colorAcent};
    }

    &.fill-green {
      --active-fill-color: #46BF52;
    }

    &.fill-blue {
      --active-fill-color: #2F5AC8;
    }

    &.fill-orange {
      --active-fill-color: #FD691F;
    }

    &.fill-white {
      --active-fill-color: white;
    }

    &:hover, &:active {
      .badge-title {
        display: block;
      }
    }

    .badge-title {
      display: none;
      box-shadow: 2px 2px 19px 0px #00000024;
      padding: 10px 10px;
      min-width: max-content;
      font-family: OpenSans;
      font-size: 12px;
      font-weight: 700;
      line-height: 14px;
      letter-spacing: 0.02em;
      text-align: center;
      height: max-content;
      border-radius: 4px;
      background-color: white;
      text-align: left;
      position: absolute;
      left: 25%;
      z-index: 1000;

      ul {
        padding: 10px;

        li {
          display: flex;
          flex-direction: column;
          margin-top: 15px;

          h3 {
            display: flex;
            align-items: center;
            text-transform: uppercase;

            span {
              font-weight: 700;
              margin-left: 10px;
            }
          }

          > span {
            margin-left: 35px;
            font-weight: 500;
          }
        }
      }

      img {
        width: 25px;
      }
    }

    .badge-icon {
      border-radius: 3px;
      height: 32px;
      width: 32px;
      padding: 4px;
      background-color: var(--active-fill-color);
      &.text {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  svg {
    stroke: #989898;
    fill: transparent;
    width: 24px;
    height: 24px;
    /* &:hover use, &:focus use, &:active use { */
    /*   color: white !important; */
    /* } */
    use {
      transition: all .3s;
      fill: transparent;
      // color: black;
      /* color: #989898; */
      color: white;
      // &.stroke-black {
      //   color: black;
      // }
    }
  } 
}

.badge {
	border-radius: 6px;
	padding: 3px 16px;
	text-align: right;
    width: fit-content;
    margin-bottom: 5px;
}

.rub {
	display: none;
}

.rubc {
	&:after {
		content: '₽';
	}
}

.component_product-item {
	position: relative;
  
  @media(max-width: $mediaSm) {
    .promo-badge__title {
      padding: 0;
      font-size: 10px;
    }

    .promo-market-price {
      padding: 0;
      font-size: 10px;
    }
  }
	
	&:before {
		content: "";
		display: none;
		background: #fff;
		border-radius: 3px;
		bottom: -3.6rem;
		box-shadow: 0px 0px 117px 0px rgba(15, 15, 15, 0.1);
		left: -.5rem;
		position: absolute;
		right: -.5rem;
		top: -.5rem;
		z-index: -1;
	}
	
	.char-list-wrap {
		display: none;
	}

	.badge {
		padding: 1px 16px;
	}
	
	.img {
		height: 276px;
		
		.hidden {
			display: none;
		}
	}
	.price-desk {
		@media (max-width: $mediaSm) {
			display: none;
		}
	}
	.hide-desk {
		@media (min-width: $mediaSm) {
			display: none;
		}
	}
	.btn-wrap {
		bottom: 0;
    display: none;

		left: 0;
		position: absolute;
		transform: translate(0, calc(100% + 15px));
		.price {
			display: flex;
			align-items: center;
			margin-right: auto;
			font-size: 20px;
		}
		
		@media (max-width: $mediaSm) {
			flex-direction: row-reverse;
			justify-content: flex-start !important;
		}
	}

	&._slider {
		.btn-wrap {
			display: flex;
			position: inherit;
			transform: none;
			width: 100%;
			align-items: center;
		}
	}
	
	.buy-btn {
		padding: 8px 19px;
	}
	
	.add-to-fav {
		height: 34px;
		width: 34px;
	}
	
	.product-favorites-add {
		cursor: pointer;
		
		&._active {
			svg {
				fill: #ef7f00;
			}
		}
	}
	
	.char-list,
	.btn-list {
		display: none;
	}
	
	&._hover {
		&:not(&._full) {
			&:hover {
				z-index: 10;
				
				&:before {
					display: block;
				}
				
				.btn-wrap {
					display: flex;
				}
			}
		}
	}
	
	&._full {
		flex-direction: row !important;
		justify-content: space-between;
		padding: 15px 0 15px 15px;

		.badge-cont {
			left: 0;
			right: unset;
		}
		
		.char-list-wrap {
			display: flex;
		}
		
		.img {
			width: 20%;
		}
		
		.content {
			width: 58%;
		}
		
		.btn-list {
			width: 18%;
			
			.count-wrap {
				height: 48px;
				
				input {
					width: 40%;
				}
				
				.switcher {
					height: 25px;
					width: 40px;
				}
			}
			
			.btn {
				padding: 13px 10px;
				width: 100%;
			}
			
			.thumb-btn {
				border-radius: 3px;
				display: block;
				padding: 5px 10px;
				text-align: center;
				width: 100%;
			}
		}
		
		.char-list,
		.btn-list {
			display: block;
		}
		
		.title,
		.price {
			font-size: size(20);
		}
		
		.char-list {
			li {
				span {
					border-top: 1px solid $colorGray;
					padding: 6px 0;
					width: 48%;
				}
			}
		}
	}
	
	@media (max-width: $mediaXxl) {
		.img {
			height: 210px;
		}
		
		&._full {
			.content {
				width: 55%;
			}
			
			.btn-list {
				width: 20%;
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.img {
			height: 221px;
		}
		
		&._full {
			flex-wrap: wrap;
			
			.img {
				width: 37%;
			}
			
			.content {
				width: 60%;
			}
			
			.btn-list {
				width: 100%;
			}
		}
	}
	
	@media (max-width: $mediaLg) {
		&._full {
			padding: 15px 15px 0;
		}
		
		&:before {
			bottom: -.5rem;
		}
		
		.btn-wrap {
			display: flex;
			justify-content: flex-end;
			position: relative;
			bottom: 0;
			left: 0;
			transform: translate(0, 0);
		}
	}
	
	@media (max-width: $mediaMd) {
		.img {
			height: 247px;
		}
		
		&._full {
			
			.img,
			.content {
				width: 100%;
			}
			
			.img {
				height: 280px;
			}
		}
	}
	
	@media (max-width: $mediaSm) {
		&._full {
			.img {
				height: 240px;
			}
		}
	}
}

._full .hide-full {
  display: none !important;
}

.product-delivery {
  display: flex;
  justify-content: center;
  margin-top: size(10);

  &__badge {
    padding: size(2) size(5);
    color: #ffffff;
    border-radius: size(3);

    &_in-stock {
      background-color: #80ed99;
    }

    &_delivered-tomorrow {
      background-color: #00bbf9;
    }
  }
}
