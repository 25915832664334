.modal {
	display: none;
	height: 100%;
	left: 0;
	position: fixed;
	top: 0;
	width: 100%;
	z-index: 1001;

	.shadow {
		background: fade-out(#000, .2);
		bottom: 0;
		left: 0;
		position: absolute;
		right: 0;
		top: 0;
	}

	.wrapper {
		background: #fff;
		border: 20px solid $colorGray;
		border-radius: 3px;
		box-shadow: 8.5px 14.722px 64px 0px rgba(0, 0, 0, 0.4);
		max-width: 698px;
		padding: 50px 65px;
		position: relative;
		width: calc(100% - 30px);
		z-index: 1;

		.title {
			font-size: size(27);
			font-weight: 700;
			text-transform: uppercase;
		}

		.modal-close {
			cursor: pointer;
			height: 20px;
			width: 20px;
		}

		&._narrow {
			max-width: 400px;
		}

		&._wide {
			max-width: 1050px;
		}
	}

	&._visible {
		display: flex;
	}

	@media (max-width: $mediaLg) {
		.wrapper {
			border-width: 10px;
			padding: 15px;

			.title {
				font-size: size(22);
			}
		}
	}
}

#modal-share {
	.wrapper {
		border-width: 10px;
		padding: 20px;
	}

	.links {
		a {
			width: 40px;
			height: 40px;
			border-radius: 20px;

			svg {
				width: 20px;
				height: 20px;
			}

			&._wa {
				background: #25d366;
			}

			&._tg {
				background: #08c;
			}

			&._vk {
				background: #45668e;
			}

			&._fb {
				background: #3b5998;
			}
		}
	}
}

#modal-city {
	.city {
		border: 1px solid $colorGray;
		border-radius: 3px;
		cursor: pointer;
		padding: 9px 15px 9px 36px;

		.icon {
			left: 10px;
			border: 1px solid $colorGray;
			border-radius: 3px;
			height: 16px;
			width: 16px;
			background: #fff;
			fill: #fff;
			padding: 2px;
		}

		&._active {
			background: $colorDark;
			border-color: $colorDark;
			color: #fff;

			.icon {
				fill: #000;
				border-color: #fff;
			}
		}
	}
}

#modal-city-ask {
	.wrapper {
		max-width: 388px;
		padding: 25px 30px 30px;
		border-width: 10px;

		.title {
			.modal-close {
				position: absolute;
				top: 6px;
				right: 0;
			}
		}

		button {
			width: 100%;
		}
	}

	@media (max-width: $media475) {
		.wrapper {
			padding: 15px 20px 20px;
		}
	}
}

#modal-call-order {
	.thumb-text {
		a {
			text-decoration: underline;

			&:hover {
				color: $colorText;
			}
		}
	}

	&-ok {
		.wrapper {
			background: $colorGray;
			padding: 50px;
		}

		.line {
			height: 2px;
			width: 100px;
		}

		img {
			position: absolute;
			right: -20px;
			top: 33%;
			width: 378px;
		}

		.social-links {
			a {
				background: #fff;
				border-radius: 3px;
				display: block;
				height: 43px;
				width: 43px;

				svg {
					border-radius: 3px;
					height: 24px;
					padding: 4px;
					width: 24px;
				}
			}
		}
	}

	@media (max-width: $mediaLg) {
		&-ok {
			.wrapper {
				padding: 10px;
			}

			img {
				right: -10px;
				top: 32%;
				width: 280px;
			}
		}
	}

	@media (max-width: $mediaMd) {
		&-ok {
			p {
				font-size: size(16);

				br {
					display: none;
				}
			}
		}
	}
}

#modal-auto-search {
	.select-wrap {
		padding-right: 250px;
	}

	img {
		position: absolute;
		right: -20px;
		top: 33%;
		width: 300px;
	}

	.wrap {
		.num {
			font-size: size(13);
			font-weight: 700;
			height: 30px;
			left: 10px;
			line-height: 30px;
			text-align: center;
			width: 18px;
		}

		.select {
			border: 1px solid $colorGray;
			padding-left: 40px;
		}
	}

	@media (max-width: $mediaLg) {
		img {
			right: -10px;
			top: 32%;
			width: 280px;
		}
	}

	@media (max-width: $mediaMd) {
		.select-wrap {
			padding-right: 0;
		}
	}
}

.modal-product {
	&__wrapper.wrapper {
		max-width: 900px;
	}

	&__photo {
		aspect-ratio: 1;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 10px;
		border: 1px solid $colorGray;

		@media(max-width: $media475) {
			padding: 3px;
		}

		img {
			max-width: 100%;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}

	&__badge {
		padding: 2px 6px;
		border-radius: 4px;
		background: $colorAcent;
		color: #fff;
		justify-content: space-between;
		align-items: center;
	}

	&__price {
		span {
			opacity: .5;
		}
	}

	&__details {
		background-color: $colorGray;
		border-radius: 4px;
		padding: 5px 25px 5px 15px;
		&-title {
			@media(max-width: $media475) {
				font-size: size(16);
			}
		}
		&-price {
			@media(max-width: $media475) {
				font-size: size(12);
			}
			span {
				font-size: size(16);
			}
		}
	}

	&__bottom {
		row-gap: 10px;
		
	}

	&__btns {
		gap: 5px 10px;
		
	}

	&__title {
		@media(max-width: $media475) {
			font-size: size(14);
		}
	}
}