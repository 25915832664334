.component_accordeon {
	.message-wrap {
		border-radius: 3px;
		height: 54px;
		padding: 13px;
		width: 54px;
	}
	
	.header {
		color: fade-out(#212426, .3);
		cursor: pointer;
		font-size: size(15);
		font-weight: 700;
		padding: 16px 25px;
		text-transform: uppercase;
	}
	
	.content {
		display: none;
		font-size: size(14);
		padding: 15px 25px 35px;
	}
	
	&._active {
		.wrap {
			background: $colorGray;
		}
		
		.header {
			color: #212426;
			
			svg {
				transform: rotate(180deg);
			}
		}
		
		.content {
			display: block;
		}
	}
	
	@media (max-width: $mediaXxl) {
		.message-wrap {
			height: 42px;
			padding: 10px;
			width: 42px;
		}
		
		.header {
			padding: 10px 15px;
		}
		
		.content {
			padding: 15px;
		}
	}
}
