.scroll-top {
  position: fixed;
  left: size(30);
  bottom: size(15);
  padding: size(18);
  border-radius: size(50);
  z-index: 100;
  cursor: pointer;
  background-color: rgba($color: $colorDarkGray, $alpha: 0.5);

  /* &:hover { */
  /*   background-color: $colorGray; */
  /* } */

  &__icon {
    width: size(20);
    height: size(20);
    fill: $colorDark;
  }
}
