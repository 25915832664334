.seo-text {
  margin-top: size(20);
  font-size: size(16);
  text-align: justify;

  p {
    padding: size(10) 0;
  }

  h1 {
    font-weight: 700;
    font-size: size(20);
    padding: size(10) 0;
  }

  ul {
    list-style: disc;
    margin-left: size(50);
    margin-bottom: size(10);
  }
}
