.bg {
	&-acent {
		background: $colorAcent;
		
		&-fade {
			background: fade-out($colorAcent, .8);
		}
		&:hover {
			background: $colorAcentHover;
		}
	}

	&-orange {
		background: $colorOrange;
	}

	&-blue {
		background: $colorBlue;
	}
	
	&-bg {
		background: $colorGray;
	}
	
	&-white {
		background: #fff;
	}
	
	&-dark {
		background: $colorDark;
		
		&-fade {
			background: #161616;
		}
		
		&-fade1 {
			background: #1d1d1d;
		}
	}
}
