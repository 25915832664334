.component_same-news {
	.icon {
		height: 54px;
		padding: 20px;
		width: 54px;
	}
	
	.title {
		line-height: 1.3;
	}
	
	img {
		height: 180px;
	}
	
	@media (max-width: $mediaXxl) {
		.icon {
			height: 44px;
			padding: 12px;
			width: 44px;
		}
		
		.title {
			font-size: size(18);
		}
		
		img {
			height: 160px;
		}
	}
	
	@media (max-width: $mediaLg) {
		img {
			height: 140px;
		}
		
		.title {
			font-size: size(14);
		}
	}
}
