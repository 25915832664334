.page_portfolio {
	.links {
		a {
			padding: 8px 15px 7px;
			
			&._wa {
				background: #25d366;
			}
			
			&._tg {
				background: #08c;
			}
			
			&._vk {
				background: #45668e;
			}
			
			&._fb {
				background: #3b5998;
			}
		}
	}

  .filter-wrap {
    svg {
      height: 16px;
      width: 16px;
      fill: #a20202;
      margin-left: -24px;
    }

    .item {
			border-radius: 3px;
			font-size: 12px;
			height: 40px;
			text-transform: uppercase;
			border: 1px solid $colorGray;
			padding: 0 40px 0 15px;
			
		}

    button.item {
      text-align: center;
      padding: 0 25px 0 25px;
    }

    .text {
      min-width: 380px;
			color: $colorDark;
			font-weight: 700;
    }
		
		.select {
			min-width: 183px;
			color: $colorDark;
			font-weight: 700;
		}
  }

	.product-item {
		.img {
			height: 74px;
			width: 74px;
		}
		
		.icon {
			border-radius: 3px;
			height: 54px;
			padding: 20px;
			width: 54px;
			
			&-star {
				fill: fade-out($colorDark, .6);
				padding: 16px;
			}
		}
	}
	
	.des-wrap {
		iframe {
			width: 100%;
			height: 400px;
		}
	}
	
	@media (max-width: $mediaXxl) {
		.product-item {
			.img {
				height: 60px;
				width: 60px;
			}
			
			.icon {
				height: 36px;
				padding: 10px;
				width: 36px;
				
				&-star {
					padding: 8px;
				}
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.des-wrap {
			iframe {
				height: 300px;
			}
		}
	}
	
	@media (max-width: $mediaLg) {
		.des-wrap {
			iframe {
				height: 230px;
			}
		}
	}
	
	@media (max-width: $mediaMd) {
		.des-wrap {
			iframe {
				height: 290px;
			}
		}
	}
	
	@media (max-width: $media475) {
		.links {
			a {
				padding: 8px 10px 7px;
			}
		}
		
		.product-item {
			.img {
				height: 70px;
				width: 70px;
			}
		}
		
		.des-wrap {
			iframe {
				height: 230px;
			}
		}
	}
	
	@media (max-width: $media375) {
		.des-wrap {
			iframe {
				height: 200px;
			}
		}
	}
	
	@media (max-width: $media320) {
		.des-wrap {
			iframe {
				height: 180px;
			}
		}
	}
}

.portfolio-filter {
  &__select {
    flex: 1 1 auto;
  }

  &__text {
    flex: 2 2 auto;
  }

  &__button {
    flex: 1 1 auto;
  }

  &__search {
    width: 100%;
  }
}
