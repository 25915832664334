.article {
  &__title-wrapper {
    display: flex;
    margin-bottom: size(20);
    justify-content: space-between;
    flex-direction: column;
    align-items: flex-start;
  }

  &-image {
    @media(min-width: $mediaMd) {
      width: 50% !important;
    }
  }

  &__title {
    margin-bottom: 0 !important;
  }

  &__author {
    // margin-left: size(40);
    display: flex;
    align-items: center;
    // margin-top: size(20);

    &-avatar {
      width: size(50);
      height: size(50);
      margin-right: size(20);
      border-radius: size(25);
      flex: 0 0 size(50);
    }

    &-name {
      font-weight: 700;
    }

    &-label {
      font-size: size(12);
    }
  }

  &__stats-author {
    display: flex;
    justify-content: space-between;
    // flex-direction: row-reverse;
    align-items: center;
    margin-bottom: size(15);
    flex-wrap: wrap;
  }

  &-image {
    float: left;
    margin-right: size(15);
    margin-bottom: size(15);
  }
}

.links {
  clear: both;
}
