#component_footer {
	margin-top: 70px;
	overflow: hidden;
	padding: 80px 0 35px;
	
	.left-side {
		padding-right: 75px;
	}
	
	.right-side {
		padding-left: 75px;
		
		.arrow-top {
			position: absolute;
			right: -30px;
			top: 10px;
			
			svg {
				height: 22px;
				width: 22px;
			}
			
			.line {
				bottom: -37px;
				height: 2px;
				left: 0;
				position: absolute;
				right: -999999px;
			}
		}
		
		.bg {
			bottom: -500%;
			left: 0;
			position: absolute;
			right: -9999px;
			top: -80px;
		}
	}
	
	.block {
		a {
			font-size: size(16);
			margin-bottom: 25px;
			position: relative;
			
			&:hover {
				color: #fff;
			}
		}
	}
	
	.social-links {
		a {
			padding-left: 32px;
			
			svg {
				background: #fff;
				border-radius: 3px;
				height: 18px;
				left: 0;
				padding: 2px;
				width: 18px;
			}
		}
	}
	
	.payment-logos {
		img {
			object-fit: contain;
			width: 50px;
			
			&._sm {
				width: 37px;
			}
		}
	}

	.thumb-info {
		a {
			&:hover {
				color: #fff;
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.left-side {
			padding-right: 15px;
		}
		
		.right-side {
			margin-top: 60px;
			padding-left: 15px;
			
			.bg {
				left: -9999px;
				top: -30px;
			}
			
			.arrow-top {
				right: 30px;
			}
		}
	}
	
	@media (max-width: $mediaLg) {
		.right-side {
			.arrow-top {
				bottom: 60px;
				right: 60px;
				top: auto;
			}
		}
	}
	
	@media (max-width: $mediaMd) {
		padding: 50px 0 15px;
		
		.block {
			nav {
				display: none !important;
				
				a {
					margin-bottom: 10px;
				}
			}
			
			&._active {
				.block-header {
					.icon {
						transform: rotate(180deg);
					}
				}
				
				nav {
					display: flex !important;
				}
			}
		}
	}
	
	@media (max-width: $media475) {
		.right-side {
			.arrow-top {
				bottom: 30px;
				
				.line {
					bottom: -25px;
				}
			}
		}
	}
}
