.component_promo-banner {
	overflow: hidden;
	height: 100%;
	padding: 30px;
	
	.bg {
		height: 45px;
		left: 0;
		top: 24%;
		transform: skewY(-20deg);
		width: 160px;
	}
	
	@media (max-width: $mediaXxl) {
		padding: 15px;
	}
	
	@media (max-width: $mediaXl) {
		.title {
			font-size: size(22);
		}
	}
}
