.component_pagination {
	.icon {
		height: 54px;
		padding: 18px;
		width: 54px;
	}
	
	button {
		height: 54px;
		text-align: left;
		width: 100%;
	}
	
	.num-list {
		a {
			height: 54px;
			line-height: 54px;
			margin-right: 10px;
			text-align: center;
			width: 54px;
		}
	}
	
	@media (max-width: $mediaLg) {
		.icon {
			height: 44px;
			padding: 14px;
			width: 44px;
		}
		
		button {
			height: 44px;
		}
		
		.num-list {
			a {
				height: 44px;
				line-height: 44px;
				width: 44px;
				margin-right: 4px;
			}
		}
	}
}
