.page_guarantee {
	.table-response {
		margin-left: -14px;
		width: calc(100% + 28px);
	}
	
	table {
		border-collapse: separate;
		border-spacing: 14px 7px;
		
		.line {
			position: relative;
			
			&:before {
				background: fade-out($colorDark, .8);
				bottom: 0;
				content: "";
				position: absolute;
				right: -8px;
				top: -7px;
				width: 1px;
			}
		}
		
		td {
			width: 33%;
		}
		
		th,
		.block {
			padding: 18px 20px;
		}
		
		.color {
			&-1 {
				background: #e1fae7;
			}
			
			&-2 {
				background: #c9ebd1;
			}
			
			&-3 {
				background: #f6c3c3;
			}
		}
		
		tr {
			&:first-child {
				.line {
					&:before {
						top: -1px;
					}
				}
			}
		}
	}
	
	.law-info {
		.icon {
			height: 54px;
			padding: 13px;
			width: 54px;
		}
	}
	
	@media (max-width: $mediaXxl) {
		.title {
			font-size: size(24);
		}
		
		table {
			th,
			.block {
				padding: 10px 15px;
			}
		}
		
		.law-info {
			.icon {
				height: 42px;
				padding: 10px;
				width: 42px;
			}
		}
	}
	
	@media (max-width: $mediaMd) {
		.sub-title {
			font-size: size(14);
		}
	}
}
