.page_basket {
	.line {
		background: #ebebeb;
		height: 1px;
	}
	
	.order-confirmed {
		padding: 70px 70px 140px;
		
		.title {
			font-size: size(40);
		}
		
		.social-links {
			a {
				background: #fff;
				border-radius: 3px;
				display: block;
				height: 43px;
				width: 43px;
				
				svg {
					border-radius: 3px;
					height: 24px;
					padding: 4px;
					width: 24px;
				}
			}
		}
		
		.car-img {
			position: absolute;
			right: 15px;
			top: 25%;
			width: 378px;
		}
	}
	
	.contact-item {
		.num {
			border-radius: 3px;
			font-size: size(14);
			font-weight: 700;
			height: 100%;
		}
		
		.content {
			display: none;
			margin-top: 20px;
		}
		
		.form-field {
			background: #fff;
			margin-bottom: 15px;
		}
		
		.btn-wrap {
			a {
				text-decoration: underline;
			}
		}
		
		.payment-item {
			background: #fff;
			border: 1px solid #fff;
			border-radius: 3px;
			cursor: pointer;
			padding: 15px;
			//margin-bottom: 15px;
			
			.payment-icon {
				background: $colorGray;
				border-radius: 3px;
				height: 30px;
				padding: 5px;
				width: 30px;
			}
			
			&._active {
				background: transparent;
				border-color: $colorDark;
				
				.payment-icon {
					background: $colorAcent;
					fill: #fff;
					&.sberpay-icon {
						background: linear-gradient( 241.57deg,  #42e3b4 29.3%, #00d900 60.42%, #faed00 85.41% );
					}
				}
			}
		}
		
		&._active {
			.arrow-icon {
				transform: rotate(180deg);
			}
			
			.content {
				display: block;
			}
		}
	}
	
	.file-upload {
		border: 2px dashed #adadad;
		
		.file {
			.remove {
				right: 8px;
				width: 14px;
				height: 14px;
			}
		}
	}
	
	@media (max-width: $mediaXxl) {
		.order-confirmed {
			padding: 30px 30px 60px;
			
			.car-img {
				top: 34%;
				width: 290px;
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.order-confirmed {
			.car-img {
				top: 37%;
				width: 220px;
			}
		}
	}
	
	@media (max-width: $mediaLg) {
		.order-confirmed {
			padding: 15px;
			
			.title {
				font-size: size(28);
			}
			
			.text {
				font-size: size(16);
			}
			
			.car-img {
				top: 50%;
				width: 240px;
			}
		}
	}
}
