.component_basket-footer {
	li {
		align-items: center;
		border-top: 1px solid $colorGray;
		display: flex;
		justify-content: space-between;
		padding: 6px 0;
	}
	
	.btn {
		width: 100%;
	}
}
