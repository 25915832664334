.section_not-found {
	.block {
		overflow: hidden;
		padding: 70px 40px;
	}
	
	.line {
		height: 2px;
		width: 100px;
	}
	
	.input-wrap {
		background: #fff;
		border-radius: 3px;
		height: 100%;
		
		svg {
			right: 20px;
		}
		
		input {
			font-size: size(13);
			font-weight: 700;
			height: 100%;
			padding: 12.5px 40px 12.5px 20px;
			text-transform: uppercase;
			width: 100%;
		}
	}
	
	@media (max-width: $mediaXl) {
		.block {
			padding: 20px 30px 30px;
		}
	}
	
	@media (max-width: $mediaMd) {
		.block {
			padding: 15px;
		}
		
		.title {
			font-size: size(32);
		}
		
		.sub-title {
			font-size: size(18);
		}
	}
}
