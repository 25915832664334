.page_news-in {
	margin-bottom: -70px;
	overflow: hidden;
	padding-bottom: 80px;
	
	.left-side {
		padding-right: 75px;
	}
	
	.right-side {
		padding-left: 75px;
	}
	
	.main-img {
		width: 100%;
	}
	
	.bg {
		background: #fff;
		bottom: -80px;
		left: -9999px;
		position: absolute;
		right: 0;
		top: -100px;
	}
	
	article {
		font-size: size(17);
		
		iframe {
			width: 100%;
		}
		
		h1 {
			font-size: size(32);
			font-weight: 700;
			line-height: 1.3;
			margin-bottom: 20px;
			text-transform: uppercase;
		}
		
		h2 {
			font-size: size(26);
			font-weight: 700;
			line-height: 1.3;
			margin-bottom: 20px;
			text-transform: uppercase;
		}
		
		h3 {
			font-size: size(20);
			font-weight: 700;
			line-height: 1.3;
			margin-bottom: 20px;
			text-transform: uppercase;
		}
		
		hr {
			background: $colorGray;
			height: 1px;
			margin: 35px 0;
		}
	}
	
	.links {
		border-top: 1px solid $colorGray;
		margin-top: 35px;
		padding-top: 35px;
		
		a {
			padding: 8px 15px 7px;
		
			&._wa {background: #25d366;}
			&._tg {background: #0088cc;}
			&._vk {background: #45668e;}
			&._fb {background: #3b5998;}
		}
	}
	
	@media (max-width: $mediaXl) {
		padding-bottom: 50px;
		
		.left-side {
			padding-right: 35px;
		}
		
		.right-side {
			padding-left: 35px;
		}
	}
	
	@media (max-width: $mediaLg) {
		padding-bottom: 30px;
		
		.bg {
			bottom: -60px;
			right: -999px;
		}
		
		.left-side {
			padding-right: 15px;
		}
		
		.right-side {
			padding-left: 15px;
		}
		
		article {
			h1 {
				font-size: size(26);
			}
			
			h2 {
				font-size: size(22);
			}
			
			h3 {
				font-size: size(18);
			}
		}
	}
	
	@media (max-width: $media475) {
		.links {
			padding-top: 0;

			.soc-link {
				padding: 0 !important;
				border-radius: 4px;
				overflow: hidden;
				width: 31px;
				height: 31px;
			}
		}
	}
}
