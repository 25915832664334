.table-response {
	margin-bottom: 15px;
	width: 100%;
}

table {
	width: 100%;
}

.table {
	border: 1px solid #ddd;
	font-size: size(12);
	margin-bottom: 20px;
	text-align: left;
	
	b, th {
		font-weight: 700;
	}
	
	tr {
		&:nth-child(odd) {
			td, td {
				background-color: #f9f9f9;
			}
		}
	}
	
	td, th {
		border: 1px solid #ddd;
		padding: 8px;
		vertical-align: top;
	}
}
