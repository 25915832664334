@import "../global/variables";

.btn {
	align-items: center;
	background: $colorGray;
	border-radius: 3px;
	color: $colorText;
	display: inline-flex;
	font-size: 12px;
	font-weight: 700;
	justify-content: center;
	padding: 16px 23px;
	text-transform: uppercase;

	&:hover {
		background: $colorDark;
		color: $colorGray;
	}
	
	&_acent {
		background: $colorAcent;
		color: #fff;
		&:hover {
			background: $colorAcentHover;
		}
	}
	
	&_dark {
		background: $colorDark;
		color: #fff;
		
		&-fade {
			background: fade-out($colorDark, .5);
			color: #fff;
		}
	}
	
	&_bg {
		background: #efefef;
		color: fade-out($colorDark, .7);
		&:hover {
			background: fade-out($colorDark, .9);
		}
	}
	
	@media (max-width: $mediaXxl) {
		padding: 13px 20px;
	}
}
