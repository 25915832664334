.f {
	/* Text case */
	&-upper {
		text-transform: uppercase;
	}
	
	&-lower {
		text-transform: lowercase;
	}
	
	&s {
		&-46 {
			font-size: size(46);
		}
		
		&-32 {
			font-size: size(32);
		}
		
		&-30 {
			font-size: size(30);
		}
		
		&-27 {
			font-size: size(27);
		}
		
		&-26 {
			font-size: size(26);
		}
		
		&-22 {
			font-size: size(22);
		}
		
		&-20 {
			font-size: size(20);
		}
		
		&-18 {
			font-size: size(18);
		}
		
		&-17 {
			font-size: size(17);
		}
		
		&-16 {
			font-size: size(16);
		}
		
		&-15 {
			font-size: size(15);
		}
		
		&-14 {
			font-size: size(14);
		}
		
		&-13 {
			font-size: size(13);
		}
		
		&-12 {
			font-size: size(12);
		}
		
		&-11 {
			font-size: size(11);
		}
		
		&-10 {
			font-size: size(10);
		}


    &-sm {
      @media(max-width: $mediaSm) {
        &-18 {
          font-size: size(18);
        }

        &-20 {
          font-size: size(20);
        }
      }
    } 
	}
	
	/* Font weight */
	&-700 {
		font-weight: 700;
	}
	
	&-600 {
		font-weight: 600;
	}
	
	&-400 {
		font-weight: 400;
	}
	
	&-300 {
		font-weight: 300;
	}
}

/* Text align */
.ta {
	&-left {
		text-align: left;
	}
	
	&-center {
		text-align: center !important;
	}
	
	&-right {
		text-align: right !important;
	}
}

/* Color */
.c {
	&-acent {
		color: $colorAcent;
	}

  &-gray {
    color: $colorGray !important;
  }

  &-darkgray {
    color: $colorDarkGray !important;
  }
	
	&-text {
		color: $colorText;
		
		&-fade {
			color: $colorTextFade;
		}
		
		&-fade1 {
			color: fade-out($colorDark, .4);
		}
	}
	
	&-white {
		color: #fff;
		
		&-fade {
			color: fade-out(#fff, .4);
		}
	}
}

/* Thumb */
a {
	color: inherit;
}

/* Text overflow */
.to {
	&-3 {
		@include text-overflow(3);
	}
	
	&-2 {
		@include text-overflow(2);
	}
	
	&-1 {
		@include text-overflow(1);
	}
}

b,
strong {
	font-weight: 700;
}

img {
	color: #eee;
	font-size: 10px;
	overflow: hidden;
}

@media (max-width: $mediaMd) {
  .fm-22 {
    font-size: size(22);
  }
	.page-title {
		font-size: size(20) !important;
	}
}
