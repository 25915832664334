@import "~nouislider/dist/nouislider.min.css";

/* Global */
@import "./inc/bootstrap/bootstrap-grid";
@import "./inc/global/_fonts.scss";
@import "./inc/global/_reset.scss";
@import "./inc/global/_custom.scss";

/* UI */
@import "./inc/ui/_button.scss";
@import "./inc/ui/_text.scss";
@import "./inc/ui/_card.scss";
@import "./inc/ui/_form.scss";
@import "./inc/ui/_icon.scss";
@import "./inc/ui/_bg.scss";
@import "./inc/ui/_border.scss";
@import "./inc/ui/_table.scss";

/* Media */
@import "./inc/media/_1199.scss";
@import "./inc/media/_992.scss";
@import "./inc/media/_768.scss";
@import "./inc/media/_576.scss";
@import "./inc/media/_475.scss";
@import "./inc/media/_375.scss";
@import "./inc/media/_320.scss";

/* Layouts */
@import "./inc/layouts/_header.scss";
@import "./inc/layouts/_footer.scss";

/* Pages */
@import "./inc/pages/_common.scss";
@import "./inc/pages/_basket.scss";
@import "./inc/pages/_about.scss";
@import "./inc/pages/_partners.scss";
@import "./inc/pages/_calculator.scss";
@import "./inc/pages/_reviews.scss";
@import "./inc/pages/_portfolio.scss";
@import "./inc/pages/_news-in.scss";
@import "./inc/pages/_contacts.scss";
@import "./inc/pages/_guarantee.scss";
@import "./inc/pages/_search.scss";
@import "./inc/pages/_product.scss";
@import "./inc/pages/_catalog.scss";
@import "./inc/pages/_error.scss";
@import "./inc/pages/contacts-info.scss";
@import "./inc/pages/article";

/* Sections */
@import "./inc/sections/_common.scss";
@import "./inc/sections/_news-list.scss";
@import "./inc/sections/_header-banner.scss";
@import "./inc/sections/_search-blocks.scss";
@import "./inc/sections/_product-list.scss";
@import "./inc/sections/_service-small-list.scss";
@import "./inc/sections/_feature-list.scss";
@import "./inc/sections/_not-found.scss";
@import "./inc/sections/_same-projects.scss";
@import "./inc/sections/_seo-texts.scss";
@import "./inc/sections/_popup-banner.scss";

/* Components */
@import "./inc/components/_common.scss";
@import "./inc/components/_article.scss";
@import "./inc/components/_service.scss";
@import "./inc/components/_product.scss";
@import "./inc/components/_promo-banner.scss";
@import "./inc/components/_search.scss";
@import "./inc/components/_basket.scss";
@import "./inc/components/_catalog-filter.scss";
@import "./inc/components/_breadcrumbs.scss";
@import "./inc/components/_basket-footer.scss";
@import "./inc/components/_delivery-method.scss";
@import "./inc/components/_basket-product.scss";
@import "./inc/components/_pagination.scss";
@import "./inc/components/_review.scss";
@import "./inc/components/_portfolio.scss";
@import "./inc/components/_accordeon.scss";
@import "./inc/components/_delivery.scss";
@import "./inc/components/_same-news.scss";
@import "./inc/components/_vs_widgets.scss";
@import "./inc/components/_tabs.scss";
@import "./inc/components/_dropdown.scss";
@import "./inc/components/arrow";
@import "./inc/components/checkboxes";
@import "./inc/components/tooltip";
@import "./inc/components/expandable";
@import "./inc/components/scroll-top";

/* Plugins */
@import "./inc/plugins/swiper.scss";

/* Modals */
@import "./inc/modals/_modal.scss";
