.tooltip:not([data-tooltip]) {
  display: inline-block;
  border: 1px solid gray;
  border-radius: 15px;
  width: 15px;
  height: 15px;
  text-align: center;
  background-color: gray;
  color: white;
  font-size: 12px;
  font-weight: 500;
  line-height: 12px;
  text-align: center;
  vertical-align: middle;
  margin-left: 8px;
  margin-top: 1px;
  cursor: help;

  &:hover {
    .tooltip-text {
      visibility: visible;
    }
  }
  
  .tooltip-text {
    left: 15px;
    right: 15px;
    margin-top: 20px;
    visibility: hidden;
    min-width: 250px;
    background-color: white;
    color: black;
    font-weight: 500;
    text-align: center;
    border-radius: 6px;
    padding: 15px;
    text-transform: none;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
    font-size: 14px;
    line-height: 14px;

    @media (max-width: $mediaSm) {
      font-size: 18px;
      line-height: 18px;
    }

    position: absolute;
    z-index: 1;
  }
}

.tooltip[data-tooltip] {
  position: relative;

  &:before {
    content: attr(data-tooltip);
    position: absolute;
    top: 50%;
    left: calc(100% + 5px);
    transform: translateY(-50%);
    padding: 5px 10px;
    text-align: center;
    display: none;
    white-space: nowrap;
    border: 1px solid gray;
    background-color: white;
    border-radius: 3px;
    z-index: 9999;
    font-size: 12px;
    font-weight: 700;
    font-family: OpenSans;
    text-transform: uppercase;
  }

  &:hover:before {
    display: block;
  }
}
