.checkboxes {
  position: relative;
  border-radius: 3px;
  border: 1px solid #f1f1f1;
  padding: 10px 10px;

  .checkboxes-toggle {
    position: absolute;
    right: 10px;
    top: 10px;
  }

  .checkboxes-content {
    input[type=text] {
      border: 1px solid #f1f1f1;
      border-radius: 3px;
      margin-top: 10px;
      padding: 12px 21px;
      text-align: left;
    }

    input ~ .icon {
      position: relative;
      top: -30px;
      left: calc(100% - 25px);
    }

    use {
      stroke: #f1f1f1;
      fill: #f1f1f1;
    }

    .checkbox-items {
      overflow: hidden;
      max-height: 200px;

      &._expanded {
        max-height: none;

        & ~ a::after {
          content: "Скрыть"
        }
      }

      & ~ a::after {
        content: "Показать все"
      }
    }
  }
}
