/* @import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap'); */
@import "./inc/global/variables";

body {
	font-family: 'OpenSans';
}

h1, h2, h3 {
	font-family: 'Supermolot';
}

.portfolio-slider {
	overflow: hidden;
}
.portfolio-slide {
	max-height: 300px;
	width: 100%;
	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
	}

}

.portfolio__btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	height: 60px;
	padding: 0 30px;
	color: #fff;
	font-weight: 500;
	font-size: 18px;
	border-radius: 6px;
}
// Видео слайд
.dnone {
	display: none;
	}
	.thumb-slide-video {
		width: 74px;
		height: 74px;
		flex-shrink: 0;
		padding: 5px;
	}
	@media (max-width: 768px) {
		.thumb-slide-video {
			width: 50px;
			height: 50px;
		}
	}
	.thumb-slide-video img {
		width: auto !important;
		height: auto !important;
		max-width: 100%;
		max-height: 100%;
		object-fit: cover;
		padding: 0 !important;
		margin: 0 !important;
	}
	.big-slide-video {
		width: 100%;
		height: 100%;
	}
	.big-slide-video,
	.thumb-slide-video {
		display: flex;
		align-items: center;
		justify-content: center;
		position: relative;
		overflow: hidden;
	}
	.big-slide-video::before,
	.thumb-slide-video::before {
		content: '';
		position: absolute;
		inset: 0;
		background-color: rgba(0,0,0,.65);
		z-index: 2;
	}

	.big-slide-video svg {
		position: absolute;
		z-index: 3;
		width: 140px;
		height: 140px;
		left: 50%;
		top: 50%;
		transform: translate(-50%,-50%);
	}
	.thumb-slide-video svg {
		position: absolute;
		z-index: 3;
		width: 24px;
		height: 24px;
		transform: scale(-1, 1);
	}
// Варианты оплаты
.btn-order {
	display: flex;
	align-items: center;
	flex-wrap: nowrap;
	gap: 20px;
}
.btn-basket {
	height: 66px;
	min-width: 210px;
}
.payment-item._active .payment-item__title {
opacity: 1;
}
.payment-item {
	height: 100%;
}
.payment-item > .row {
	flex-wrap: nowrap;
	height: 100%;
}
.payment-item svg {
	opacity: .8;
	fill: #0f0f0f;
}
.payment-item._active svg {
	opacity: 1;
	fill: #fff;
}
.payment-item__text {
	margin-left: 12px;
	padding-right: 20px;
}
.payment-item__title {
	font-size: 12px;
line-height: 15px;
font-weight: 700;
color: #0f0f0f;
opacity: 0.8;
}
.payment-item__desc {
	font-size: 12px;
line-height: 15px;
font-weight: 400;
color: #0f0f0f;
opacity: 0.5;
}
@media (max-width: 550px) {
	.btn-wrap {
		flex-wrap: wrap;
		row-gap: 7px;
	}
	.portfolio__btn {
		width: 100%;
	}
}

#product-main-img-wrap {
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
}
.tabs {
	flex-direction: column;
}
// Слайдер в продукте
.bottom-slider {
	.row {
		flex-wrap: initial;
	}
	&__wrapper {
		display: flex;
		flex-direction: column;
	}
	&__init {
		overflow: hidden;
	}
	.swiper-slide {
		a {
			display: flex;
			outline: none;
			aspect-ratio: 4/3;
			img {
				position: absolute;
				inset: 0;
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
		}
	}
	&__dots {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;
		span {
			width: 10px;
	height: 10px;
	border-radius: 100%;
	border: 1px solid #9e9e9e;
		margin: 0;
		}
		.swiper-pagination-bullet-active {
			background-color: #9e9e9e;
		}
	}
	@media (max-width: 768px) {
		margin-bottom: 0 !important;
	}
}
//
// Меню
.top-links__toggle-wrapper {
	position: relative;
}
.top-links__toggle {
	text-align: right;
	display: block;
	padding: 14px 0;
	border-top: 1px solid #333;
}
.top-links__menu {
	position: absolute;
	right: 0;
	top: 100%;
	z-index: 10;
	background: #191919;
	padding: 10px 15px;
	border: 1px solid #333;
	min-width: 150px;
	display: none;
}
.top-links__menu:after {
	content: '';
	border: solid #333;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
	transform: rotate(-135deg);
	position: absolute;
	right: 21px;
	top: -5px;
	background-color: #191919;
}
#mobile-menu .top-links .top-links__menu > a {
	padding: 7px;
	border: none;
	white-space: nowrap;
	font-size: 13px;
}
//
// Фильтр
.page-title {
	outline: none;
}

.tabs-slider__init {
	overflow: hidden;
	height: 100%;
}

.tabs-slider__nav {
	display: flex;
	margin-top: 1.7rem;
	flex-shrink: 0;
}

.tabs-slider__nav>div {
	width: 100%;
	color: #000;
	cursor: pointer;
	display: block;
	font-size: 1.1em;
	font-weight: 500;
	line-height: 1em;
	text-align: center;
	border-bottom: 2px solid rgba(239, 237, 239, .5);
	padding-bottom: 10px;
}

.tabs-slider__nav>div.swiper-button-disabled {

	border-bottom: 2px solid #fd264f;
}

.tabs-slider__wrapper {
	overflow: hidden;
}

.aside-full-page-component {
	height: 100%;
}

.tabs-slider {
	flex: 1;
	display: flex;
	flex-direction: column;
	height: calc(100% - 71px);
}

.tabs-slider__wrapper {
	flex: 1;
}

.tabs-slider__item,
.tabs-slider__item form {
	height: 100%;
}

.tabs-slider__item form {
	display: flex;
	flex-direction: column;
}

.tabs-wrapper {
	flex: 1;
	overflow: auto;
	height: calc(100% - 100px);
  -webkit-overflow-scrolling: touch;
  overscroll-behavior: contain;
  transform: translate3d(0,0,0);
}

.tabs-wrapper .col {
	flex-grow: initial;
}

.tabs-footer {
	margin-top: auto;
	flex-shrink: 0;
}

.tabs-wrapper::-webkit-scrollbar-track {
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background-color: #F5F5F5;
}

.tabs-wrapper::-webkit-scrollbar {
	width: 10px;
	background-color: #F5F5F5;
}

.tabs-wrapper::-webkit-scrollbar-thumb {
	background-color: #a20202;
	background-image: -webkit-linear-gradient(45deg,
			rgba(255, 255, 255, .2) 25%,
			transparent 25%,
			transparent 50%,
			rgba(255, 255, 255, .2) 50%,
			rgba(255, 255, 255, .2) 75%,
			transparent 75%,
			transparent)
}

//


// Баннер
.n-banner {
	margin-top: 30px;

	&__wrapper {
		overflow: hidden;
		position: relative;
	}

	&__init {}

	&__item {
		position: relative;
		padding: 40px 50px 50px;
		border-radius: 4px;
		overflow: hidden;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: linear-gradient(93.11deg, rgba(15, 15, 15, 0.8) 2.57%, rgba(15, 15, 15, 0) 91.12%);
			z-index: 1;
		}

		>img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		>div {
			position: relative;
			z-index: 2;
		}
	}

	&__title {
		letter-spacing: 0.02em;
		max-width: 600px;
	}

	&__desc {
		margin-top: 24px;

	}

	&__btns {
		font-size: 0;
		display: flex;
		align-items: center;
		margin-top: 40px;

		a+a {
			margin-left: 20px;
		}
	}

	&__dots {
		position: absolute;
		bottom: 74px;
		display: flex;
		align-items: center;
		justify-content: center;
		left: 0;
		right: 0;
		z-index: 5;

		span+span {
			margin-left: 8px;
		}
	}

	.swiper-pagination-bullet {
		width: 8px;
		height: 8px;
		background-color: #fff;
		opacity: 0.6;
		border-radius: 100%;
		cursor: pointer;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.swiper-pagination-bullet-active {
		width: 14px;
		height: 14px;
		border: 1px solid ;
		background-color: transparent;
		opacity: 1;
	}

	.swiper-pagination-bullet-active::before {
		content: '';
		position: absolute;
		width: 8px;
		height: 8px;
		border-radius: 100%;
		background-color: #fff;
	}
}


.n-btn {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	line-height: 120%;
	text-align: center;
	letter-spacing: 0.04em;
	background: $colorAcent;
	border-radius: 4px;
	height: 60px;
	padding: 0 30px;
}

.n-btn-border {
	background-color: transparent;
	border: 1px solid #FFFFFF;
}

.n-btn-sub {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	border: 1px solid $colorAcent;
	height: 48px;
	border-radius: 4px;

	font-weight: 700;
	font-size: 12px;
	letter-spacing: 0.04em;
	text-transform: uppercase;
	padding: 0 30px;
}

// Этапы
.n-steps {
	margin-top: 85px;



	.col {
		&:nth-child(2n) {
			.n-steps__item {
				background: #fff;
			}
		}

		&:nth-child(3n) {
			.n-steps__counter {
				&::after {
					width: 225px;
					right: auto;
					left: calc(100% + 10px);
					background: linear-gradient(90deg, #E4192C 0%, rgba(228, 25, 44, 0) 100.5%);
				}
			}
		}
	}

	&__item {
		padding: 17px 40px 25px 25px;
		background: linear-gradient(134.73deg, rgba(177, 185, 202, 0.087) -0.13%, rgba(177, 185, 202, 0.216) 28.87%, rgba(177, 185, 202, 0.051) 61.08%, rgba(177, 185, 202, 0.12) 102.97%);
		box-shadow: 1px 1px 15px rgba(0, 0, 0, 0.1);
		backdrop-filter: blur(62px);
		border-radius: 4px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		height: 100%;

	}

	&__counter {
		font-weight: 600;
		font-size: 22px;
		line-height: 120%;
		letter-spacing: 0.02em;
		color: $colorAcent;
		margin-bottom: 25px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: -25px;
			width: 16px;
			top: 0;
			bottom: 0;
			height: 1px;
			margin: auto;
			background: $colorAcent;
		}

		&::after {
			content: '';
			position: absolute;

			right: calc(-50vw - 10px);
			width: 50vw;
			height: 1px;
			top: 0;
			bottom: 0;
			margin: auto;
			background-color: $colorAcent;
			display: block;
			z-index: 1;
		}
	}

	&__title {
		font-weight: 700;
		font-size: 18px;
		line-height: 120%;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: #000000;
	}

	&__desc {

		margin-top: 20px;
		margin-bottom: 35px;
		font-weight: 300;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #888888;

	}

	&__btn {
		margin-top: auto;
	}

	&__item-icon {
		position: absolute;
		right: 40px;
		top: 70px;

		svg {
			max-width: 65px;
			max-height: 65px;
		}
	}
}

.f-supermolot {
  font-family: Supermolot;
}

.section-title span {
	color: #BFBFBF;
}

.section-desc {
	margin-top: 10px;
}

.bg-green {
	background: #59CB64;
}

.bg-red {
	background: $colorAcent;
}

.to-catalog {
	margin-top: 40px;

	&__link {
		height: 56px;
		display: flex;

		&::after {
			content: '';
			width: 56px;
			background: #F1F1F1 url('../images/n-top.svg') no-repeat center;
			background-size: 24px;
			flex-shrink: 0;
			margin-left: 4px;
		}
	}


	span {
		flex: 1;
		height: 100%;
		background: #F1F1F1;
		border-radius: 4px;
		display: flex;
		align-items: center;
		padding: 0 24px;
	}
}


.advantages {
	margin-top: 120px;

	&__title {
		position: relative;
		padding-bottom: 23px;
		margin-bottom: 20px;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			width: 70%;
			bottom: 0;
			height: 1px;
			background-color: #E4192C;
		}
	}

	&__grid {}

	&__grid-item {
		display: flex;
		flex-direction: column;
	}

	&__grid-item-icon {
		height: 88px;
		padding-bottom: 28px;
		border-bottom: 1px solid #E4192C;
		flex-shrink: 0;
		margin-bottom: 21px;

		svg,
		img {
			max-height: 100%;
			width: auto;
		}
	}

	&__grid-item-title {
		color: $colorAcent;
	}

	&__grid-item-desc {
		color: #A3A3A3;
		font-size: 14px;
		letter-spacing: 0.02em;
		margin-top: 17px;
	}
}

.n-works {
	margin-top: 100px;

	&__wrapper {
		margin-top: 30px;
		position: relative;

		.prev,
		.next {
			position: absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			width: 56px;
			height: 56px;
			cursor: pointer;
			display: inline-flex;
			align-items: center;
			justify-content: center;
			background: #F1F1F1;
			border-radius: 4px;
		}

		.prev {
			left: -68px;
		}

		.next {
			right: -68px;
		}
	}

	.swiper-slide {
		height: auto;
	}

	&__init {
		overflow: hidden;
	}

	&__item {}

	&__product {
		padding: 12px 20px 40px;
		box-shadow: inset 1px 1px 0px #ECECEC;
		border-radius: 4px;
		background-color: #fff;
	}

	&__product-image {
		height: 385px;
		display: flex;
		align-items: center;
		justify-content: center;

		img {
			max-width: 100%;
			max-height: 100%;
		}
	}

	&__product-info {}

	&__product-title {}

	&__product-price {}

	&__product-btn {
		margin-top: 20px;
	}

	&__big-image {
		margin-bottom: 12px;
		border-radius: 4px;

		img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}

.swiper-button-lock {
	display: none !important;
}

.n-form {
	margin-top: 100px;

	&__wrapper {
		position: relative;
		padding: 40px 50px;
		border-radius: 4px;
		overflow: hidden;

		>img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			right: 0;
			top: 0;
			bottom: 0;
			background: linear-gradient(93.11deg, rgba(15, 15, 15, 0.8) 2.57%, rgba(15, 15, 15, 0) 91.12%);
			z-index: 1;
		}

		div {
			position: relative;
			z-index: 3;
		}
	}

	.section-desc {
		margin-top: 20px;
	}

	&__wrapper-form {}

	&__fields {
		margin-top: 30px;
	}

	&__row {
		+.n-form__row {
			margin-top: 1rem;
		}
	}

	&__btns {
		display: flex;
		align-items: center;
		margin-top: 2rem;

		input {
			width: auto;
			color: #fff;
		}
	}

	&__input {
		input {
			width: 100%;
			background: #FFFFFF;
			border-radius: 4px;
			font-weight: 700;
			font-size: 12px;
			letter-spacing: 0.04em;
			text-transform: uppercase;
			padding: 0 20px;
			color: #000;
			height: 60px;

			&::placeholder {
				opacity: 1;
				color: #000;
			}
		}
	}

	&__checkbox {
		margin-left: 24px;
		font-size: 12px;
		line-height: 120%;
		letter-spacing: 0.02em;
		color: #FFFFFF;

		a {
			text-decoration: underline;
		}
	}
}

.n-about {
	margin-top: 100px;

	&__gallery {
		display: flex;

		flex-direction: column;
		align-items: flex-end;

		img {
			max-width: 100%;
			object-fit: cover;

			+img {
				margin-top: 18px;
			}
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		margin-top: -10px;
	}

	&__title {
		font-size: 32px;
		padding-bottom: 30px;
		position: relative;

		&::before {
			content: '';
			position: absolute;
			left: -50px;
			width: 195px;
			bottom: 0;
			height: 1px;
			background-color: $colorAcent;
		}
	}

	&__text {
		margin-top: 30px;

		p {
			margin-bottom: 2rem;
		}

	}

	&__btn {
		margin-top: auto;
	}
}

.n-prems {
	margin-top: 100px;

	&__wrapper {
		display: grid;
		grid-template-columns: repeat(4, 1fr);
		gap: 100px;
		counter-reset: section;
	}

	&__item {
		counter-increment: num;
	}

	&__top {
		position: relative;
		border-bottom: 1px solid $colorAcent;
		position: relative;
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		margin-bottom: 25px;

		>img,
		>svg {
			height: 60px;
			width: auto;
			margin-bottom: 25px;
		}

		&::after {
			content: "0"counter(num);
			font-weight: 800;
			font-size: 60px;
			line-height: 1;
			margin-bottom: -5px;
			color: #ECECEC;

		}
	}

	&__title {
		font-weight: 700;
		font-size: 20px;
		line-height: 140%;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		margin-bottom: 20px;
	}

	&__desc {
		font-weight: 400;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #A3A3A3;
	}
}

.n-faq {
	margin-top: 100px;

	&__wrapper {
		margin-top: 25px;
	}

	&__item {
		border: 1px solid #ECECEC;
		border-radius: 4px;
		padding: 22px 150px 22px 25px;
		cursor: pointer;
		position: relative;
		display: flex;
		flex-direction: column;
		transition-duration: .15s;

		&::after {
			content: '';
			position: absolute;
			right: 9px;
			width: 44px;
			top: 0;
			bottom: 0;
			height: 44px;
			margin: auto;
			background-color: #F1F1F1;
			border-radius: 2px;
			background-image: url('../images/down.svg');
			background-size: 24px;
			background-repeat: no-repeat;
			background-position: 50%;
			transition-duration: .15s;
		}

		+div {
			margin-top: 15px;
		}

		&.active {
			border-color: $colorAcent;

			&::after {
				background-color: transparent;
				transform: scale(1, -1);
			}
		}
	}

	&__title {
		font-weight: 700;
		font-size: 14px;
		line-height: 120%;
		letter-spacing: 0.04em;
		text-transform: uppercase;
	}

	&__text {
		padding-top: 20px;
		font-size: 16px;
		line-height: 140%;
		letter-spacing: 0.02em;
		color: #A3A3A3;
	}
}


.single-product__additional {
	&-init {
		overflow: hidden;
	}

	margin: 20px 0 0 0px;

	.swiper-slide {
		a {
			height: 80px;
			display: flex;
			align-items: center;
			justify-content: center;
			padding: 5px;
			border: 1px solid #ececec;
			border-radius: 4px;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}

	&-dots {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 15px;

		span {
			width: 10px;
			height: 10px;
			border-radius: 100%;
			border: 1px solid #9e9e9e;

			+span {
				margin-left: 7px;
			}
		}

		.swiper-pagination-bullet-active {
			background-color: #9e9e9e;
		}
	}
}

.single-product-slider {
	display: flex;

	&__nav {
		width: 70px;
		margin-right: 30px;
		flex-shrink: 0;
	}

	&__nav-init {
		overflow: hidden;

		.swiper-slide {
			height: 70px;
			padding: 5px;
			box-shadow: inset 1px 1px 0px #ECECEC;
			border-radius: 4px;
			background-color: #fff;
			border: 1px solid transparent;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.swiper-slide-thumb-active {
			border: 1px solid $colorAcent;
		}
	}

	&__main {
		flex: 1;
		min-width: 0;
		padding: 10px;
		box-shadow: inset 1px 1px 0px #ECECEC;
		border-radius: 4px;
	}

	&__main-init {
		overflow: hidden;

		.swiper-slide {
			height: 385px;
			display: flex;
			align-items: center;
			justify-content: center;

			img {
				max-width: 100%;
				max-height: 100%;
			}
		}
	}
}

.swiper-pointer-events.swiper-vertical {
	touch-action: pan-x;
}

.swiper-vertical>.swiper-wrapper {
	flex-direction: column;
}




.n-production {
	margin-top: 20px;

	&__image {
		img {
			max-width: 100%;
			height: auto;
		}
	}

	&__info {
		padding-left: 40px;
		margin-top: -10px;
	}

	&__title {
		padding-bottom: 25px;
		margin-bottom: 25px;
		position: relative;

		&::after {
			content: '';
			position: absolute;
			left: -50px;
			width: 195px;
			bottom: 0;
			height: 1px;
			background-color: $colorAcent;
		}
	}

	&__text {
		font-size: 18px;
	}
}

.n-video {
	margin-top: 100px;

	&__wrapper {
		height: 405px;
		position: relative;
		border-radius: 4px;
		overflow: hidden;
		display: flex;
		align-items: center;
		padding: 0 110px;

		>img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}

		&::before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			right: 0;
			background: linear-gradient(93.11deg, rgba(15, 15, 15, 0.8) 2.57%, rgba(15, 15, 15, 0) 91.12%);
			opacity: 0.5;
			z-index: 1;
		}
	}

	&__play {
		position: relative;
		z-index: 3;
		padding-left: 149px;
		display: flex;
		align-items: center;
		font-weight: 700;
		font-size: 32px;
		line-height: 120%;
		letter-spacing: 0.02em;
		text-transform: uppercase;
		color: #fff;

		&::before {
			content: '';
			position: absolute;
			left: 0;
			width: 114px;
			height: 114px;
			background: $colorAcent url('../images/play.svg') no-repeat center;
			background-size: 40px;
			border-radius: 4px;
		}
	}
}

@media (max-width: 1350px) {
	.n-works__wrapper {
		width: calc(100% - 110px);
		margin: 1.5rem auto 0;
	}
}

@media (max-width: 1300px) {
	.n-banner__title {
		font-size: 30px;
	}

	.n-banner__dots {
		justify-content: flex-end;
		padding-right: 80px;
	}

	.n-steps .section-title {
		font-size: 24px;
	}

	.n-steps .section-title br {
		display: none;
	}

	.n-steps,
	.advantages,
	.n-works,
	.n-form,
	.n-about,
	.n-faq,
	.n-video {
		margin-top: 60px;
	}

	.n-steps__item {
		overflow: hidden;
	}

	.n-steps__item-icon svg {
		max-width: 40px;
		max-height: 40px;
	}

	.n-steps__desc {
		font-size: 14px;
	}

	.n-steps__desc br {
		display: none;
	}

	.n-steps__item {
		padding: 15px;
	}

	.n-steps__item-icon {
		right: 20px;
	}

	.n-works__product-image {
		height: 280px;
	}

	.n-prems__wrapper {
		gap: 30px;
	}

	.n-prems__title {
		font-size: 18px;
	}

	.n-prems__desc {
		font-size: 14px;
	}
}

@media (max-width: 992px) {
	.n-banner__desc {
		font-size: 18px;
	}

	.advantages__grid {
		margin-top: 2rem;
	}

	.advantages__grid .col-12:not(:last-child) {
		margin-bottom: 1.5rem;
	}

	.n-works__big-image img {
		position: relative;
		min-height: 200px;
		max-height: 300px;
	}

	.n-works__product {
		padding: 15px;
	}

	.n-works__product-btn {
		margin-top: 10px;
	}

	.n-form__row>div+div {
		margin-top: 1rem;
	}

	.n-form__wrapper {
		padding: 20px;
	}

	.n-about .row {
		flex-direction: column-reverse;
	}

	.n-about__gallery {
		margin-top: 1.5rem;
	}

	.n-about__title {
		font-size: 26px;
	}

	.n-prems__wrapper {
		grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
	}

	.n-production__info {
		margin: 1.5rem 0 0;
		padding: 0;
	}

	.n-steps__item-icon {
		right: 20px;
		top: 10px;
	}

	.n-steps__counter::after {
		display: none;
	}

	.advantages__grid-item-icon {
		height: 50px;
		padding-bottom: 10px;
		margin-bottom: 15px;
	}
}

@media (max-width: 768px) {
	.n-about__text p {
		margin-bottom: 1rem;
	}

	.n-production__title {
		font-size: 20px;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}

	.card-mobile-bottom {
		margin-top: 1.5rem;
	}

	.n-production__text {
		font-size: 14px;
	}

	.n-about__title {
		font-size: 20px;
		padding-bottom: 15px;
	}

	.n-about__text {
		margin-top: 15px;
	}

	.n-about__title br {
		display: none;
	}

	.n-about__text {
		font-size: 14px;
	}

	.n-about__gallery img {
		max-height: 160px;
	}

	.n-banner__dots {
		position: relative;
		bottom: auto;
		left: auto;
		right: auto;
		padding: 0;
		justify-content: center;
		margin-top: 10px;
	}

	.n-banner .swiper-pagination-bullet {
		background-color: #000;
		opacity: 1;
	}

	.n-banner .swiper-pagination-bullet-active {
		background-color: transparent;
	}

	.n-banner__item {
		padding: 20px;
	}

	.n-banner__title {
		font-size: 24px;
	}

	.n-btn {
		height: 50px;
		padding: 0 20px;
	}

	.n-steps {
		margin-top: 30px;
	}

	.n-steps .section-title {
		font-size: 18px;
	}

	.section-desc {
		font-size: 16px;
	}

	.advantages__grid-item-title {
		font-size: 16px;
	}

	.advantages__grid-item-title br {
		display: none;
	}

	.advantages__grid-item-desc {
		font-size: 12px;
		margin-top: 10px;
	}

	.advantages__grid .col-12:not(:last-child) {
		margin-bottom: 1rem;
	}

	.advantages__desc {
		font-size: 14px;
	}

	.n-works,
	.n-about,
	.n-faq,
	.n-video {
		margin-top: 40px;
	}

	.to-catalog {
		margin-top: 20px;
	}

	.advantages__title {
		font-size: 18px;
		padding-bottom: 15px;
		margin-bottom: 15px;
	}

	.advantages__grid {
		margin-top: 1rem;
	}

	.n-form {
		margin-top: 40px;
	}

	.n-form__wrapper-form .section-title {
		font-size: 20px;
	}

	.n-prems__top>img,
	.n-prems__top>svg {
		height: 40px;
		margin-bottom: 10px;
	}

	.n-prems__top::after {
		font-size: 40px;
	}

	.n-video__wrapper {
		height: auto;
		min-height: 230px;
		padding: 40px;
	}

	.n-video__play {
		padding-left: 80px;
		font-size: 22px;
	}

	.n-video__play::before {
		width: 60px;
		height: 60px;
		background-size: 20px;
	}


}

@media (max-width: 576px) {
	.n-video__wrapper {
		padding: 15px;
	}

	.single-product__additional {
		margin: 30px auto 0;
	}

	.single-product__additional .swiper-slide a {
		height: auto;
	}

	.n-banner__btns {
		margin-top: 20px;
		flex-direction: column;
		align-items: flex-start;
	}

	.n-banner__btns a+a {
		margin: 10px 0 0;
	}

	.n-banner__title {
		font-size: 20px;
	}

	.n-banner__desc {
		font-size: 14px;
		margin-top: 20px;
	}

	.n-btn-sub {
		padding: 0 15px;
	}

	.n-steps__title {
		font-size: 16px;
	}

	.n-steps__desc {
		margin: 15px 0 20px;
		font-size: 12px;
	}

	.n-steps__counter {
		margin-bottom: 10px;
	}

	.n-steps__title br {
		display: none;
	}

	.n-btn-sub {
		height: 40px;
	}

	.n-works__wrapper {
		width: 100%;
	}

	.n-works__wrapper .prev,
	.n-works__wrapper .next {
		display: none;
	}

	.n-form__btns {
		flex-direction: column;
	}

	.n-form__checkbox {
		margin: 10px 0 0;
		text-align: center;
	}

	.n-form__input input {
		height: 50px;
		font-size: 11px;
		padding: 0 10px;
	}

	.n-faq__item {
		padding: 15px 60px 15px 15px;

	}

	.n-faq__title {
		min-height: 40px;
		display: flex;
		align-items: center;
	}

	.n-faq__text {
		font-size: 14px;
		padding-top: 10px;
	}

	.single-product-slider__main-init .swiper-slide {
		height: auto;
		max-height: 300px;
	}

	.single-product-slider__nav {
		width: 50px;
		margin-right: 10px;
	}

	.single-product-slider__nav-init .swiper-slide {
		height: 50px;
	}
}

.grecaptcha-badge {
  visibility: hidden;
}

.roistat-multiwidget-pulsator-holder {
  bottom: 0 !important;
  right: size(15) !important;
  left: auto !important;
  top: auto !important;
}
