.shadow-bg {
	background: linear-gradient(90deg, rgb(23, 23, 23) 0%, rgba(23, 23, 23, 0) 100%);
}

.aside-full-page-component {
	background: #fff;
	bottom: 0;
	position: fixed;
	right: -640px;
	top: 0;
	transition: all .3s;
	width: 640px;
	z-index: 998;
	box-shadow: -10px 0px 8px 0px rgba(34, 60, 80, 0.06);
	
	.inner-wrap {
		padding: 3% 50px;
		box-shadow: -3px -3px 15px rgba(0,0,0,.2);
		z-index: 5;
	}
	
	.title {
		padding: 20px 50px;
		
		.close-icon {
			height: 18px;
			width: 18px;
		}
	}
	
	.line {
		background: #ebebeb;
		height: 1px;
	}
	
	&._visible {
		transform: translateX(-100%);
	}

  &.media-lg {
    @media (min-width: $mediaLg) {
      display: none !important;
    }
  }
	
	@media (max-width: $mediaXl) {
		right: -380px;
		width: 380px;
		
		.title {
			padding: 15px 30px;
		}
		
		.inner-wrap {
			padding: 17px;
		}
	}
	
	@media (max-width: $mediaSm) {
		right: -100%;
		width: 100%;
	}
	
}
