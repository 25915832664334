.section_feature-list {
	.item {
		height: 100%;
		padding: 36px 30px 30px;
		
		&:hover {
			background: $colorGray;
			
			svg {
				fill: $colorAcent;
			}
		}
	}
	
	svg {
		height: 52px;
		width: 52px;
	}
	
	.line {
		height: 2px;
	}
	
	@media (max-width: $mediaXxl) {
		.item {
			padding: 15px;
		}
	}
	
	@media (max-width: $mediaXl) {
		svg {
			height: 44px;
			width: 44px;
		}
	}
}
