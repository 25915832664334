.page_partners {
	.line {
		background: $colorAcent;
		height: 1px;
		width: 225px;
		
		&._wide {
			width: 65%;
		}
	}
	
	.img {
		height: 390px;
		
		.play-btn {
			background: fade-out($colorAcent, .2);
			cursor: pointer;
			height: 150px;
			padding: 30px;
			width: 150px;
		}
	}
	
	p {
		font-size: size(16);
		line-height: 1.7;
	}
	
	.list {
		&-item {
			.num {
				height: 100%;
				width: 46px;
			}
			
			.content {
				padding: 20px 20px 19px;
			}
		}
	}
	
	@media (max-width: $mediaXl) {
		.img {
			height: 300px;
		}
		
		p {
			font-size: size(14);
		}
		
		.ta-right {
			text-align: left !important;
		}
	}
	
	@media (max-width: $mediaLg) {
		.list {
			&-item {
				.num {
					width: 36px;
				}
				
				.content {
					padding: 10px;
				}
			}
		}
	}
	
	@media (max-width: $mediaMd) {
		.img {
			height: 240px;
		}
		
		.fs-30 {
			font-size: size(26);
		}
	}
	
	@media (max-width: $mediaSm) {
		.img {
			height: 200px;
		}
	}
}
