@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSans-Bold.woff2") format("woff2"),
	url("../fonts/OpenSans-Bold.woff") format("woff");
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSans-SemiBold.woff2") format("woff2"),
	url("../fonts/OpenSans-SemiBold.woff") format("woff");
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSans-Regular.woff2") format("woff2"),
	url("../fonts/OpenSans-Regular.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "OpenSans";
	src: url("../fonts/OpenSans-Light.woff2") format("woff2"),
	url("../fonts/OpenSans-Light.woff") format("woff");
	font-weight: 300;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: "Supermolot";
	src: url("../fonts/TT_Supermolot_Neue_Expanded_ExtraBold_Italic.woff2") format("woff2"),
	url("../fonts/TT_Supermolot_Neue_Expanded_ExtraBold_Italic.woff") format("woff");
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}
