.component_delivery-item {
	.l-icon {
		height: 54px;
		padding: 12px;
		width: 54px;
	}
	
	p {
		line-height: 1.5;
	}
	
	.btn {
		svg {
			height: size(18);
			width: size(18);
		}
	}
}
