.icon {
	height: 1rem;
	width: 1rem;
	
	&-acent {
		fill: $colorAcent;
	}
	
	&-dark {
		fill: $colorDark;
	}

  &-bg {
    fill: $colorGray;
  }
	
	&-white {
		fill: #fff;
	}

  &-text {
		fill: #fff;
		
		&-fade {
			fill: fade-out(#fff, .4);
		}

  }
}
