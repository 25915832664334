.brands-grid {
	border-radius: 5px;
	border: 1px solid #EFEFEF;
	padding: 2rem 2rem 1rem;

	@media (max-width: $mediaSm) {
		padding: 1rem;
	}

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 5px;
		border: 1px solid #EFEFEF;
		color: #A20202;
		font-size: 15px;
		font-weight: 400;
		line-height: 120%;
		padding: 20px 10px;
		height: 100%;
		text-align: center;

		@media (max-width: $mediaSm) {
			padding: 10px;
			font-size: 14px;
		}
	}
}

.brands-list {
	.brands-grid__item {
		padding: 0;
		border: none;
	}
}

.mobile-grid {
	@media (max-width: $mediaSm) {
		width: 100%;

		.mobile-grid__wrapper {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			padding: 0 10px;
			gap: 10px;

			>* {
				margin: 0 !important;
				padding: 0 !important;
			}
		}
	}
}

.page_catalog {
	.open-filter {
		min-width: 150px;
		color: #fff;

		&.for-mobile {
			display: none;
		}
	}

	.filter-wrap {
		.item.select {
			color: $colorDark;

		}

		.item {
			border-radius: 3px;
			font-size: 12px;
			font-weight: 700;
			height: 40px;
			text-transform: uppercase;

			svg {
				height: 20px;
				width: 20px;
			}

			@media(max-width: $media375) {
				font-size: 11px;

				&:is(div) {
					min-width: 140px;
				}
			}
		}

		.select {
			border: 1px solid $colorGray;
			min-width: 150px;
			padding: 0 40px 0 15px;

			@media(max-width: $mediaSm) {
				width: 100%;
			}

			@media(max-width: $media375) {
				min-width: 140px;
			}
		}

		.size-list {
			height: auto !important;
			padding: 7px 15px;

			.size {
				margin: 3px 0;
				background: #fff;
				border-radius: 3px;
				cursor: pointer;
				height: 20px;
				line-height: 20px;
				text-align: center;
				width: 35px;

				&._active {
					background: $colorAcent;
					color: #fff;
				}
			}
		}

		.sort-btn {
			border: 1px solid $colorGray;
			padding: 0 11px;

			&._active {
				svg {
					transform: rotate(180deg);
				}
			}
		}

		.view-type {
			padding: 0 10px;

			&-item {
				border-radius: 3px;
				cursor: pointer;
				height: 30px;
				width: 35px;

				svg {
					fill: fade-out($colorDark, .7);
					height: 18px;
					width: 18px;
				}

				&._active {
					background: #fff;

					svg {
						fill: $colorDark;
					}
				}
			}
		}
	}

	#catalog-prod-wrap {
		&._full {
			&>.col {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}

		.component_product-item {
			height: 100%;
			margin-bottom: 0;

			.title {
				&:hover {
					color: $colorTextFade;
				}
			}
		}
	}

	@media (max-width: $mediaMd) {
		.filter-wrap {
			.item {
				height: 30px;
			}

			.open-filter {
				padding: 0 14px;
			}

			.view-type {
				&-item {
					height: 24px;
					width: 32px;

					svg {
						height: 16px;
						width: 16px;
					}
				}
			}
		}
	}

	@media (max-width: $mediaSm) {
		.mobile-grid {
			.filter-wrap {
				.open-filter {
					width: 100%;
					justify-content: space-between;
				}
			}

			.open-filter.for-mobile {
				background: $colorAcent;
				border-radius: 3px 0 0 3px;
				bottom: 85px;
				height: 50px;
				padding: 0 15px !important;
				position: fixed;
				right: 0;
				z-index: 50;
				display: flex;
				color: #fff;

				svg {
					fill: #fff;
				}
			}
		}
	}

	@media (max-width: $media475) {
		#catalog-prod-wrap {
			&>.col {
				flex: 0 0 100%;
				max-width: 100%;
			}
		}
	}
}

.aside {
	background: #fff;
	width: 352px;
	height: fit-content;
	margin-right: size(20);

	.inner-wrap {
		padding: 3% 50px;
		z-index: 5;
	}

	.title {
		padding: 20px 50px;

		.close-icon {
			height: 18px;
			width: 18px;
		}
	}

	.line {
		background: #ebebeb;
		height: 1px;
	}

	// @media (max-width: $mediaLg) {
	// 	display: none !important;
	// }
}

.aside {
  @media (max-width: $mediaLg) {
    // display: block !important;
    background: #fff;
    left: 0;
    bottom: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 998;
    height: 100%;
  }
}

._rim-filter-toggle {
  .aside {
    display: none !important;
  }

  .car-search-wrap {
    display: block;
    margin-bottom: size(10);
  }
}

.rims-page .mobile-grid {
  display: block;
  padding-left: 13px;
  padding-right: 13px;

  @media (min-width: $mediaLg) {
    display: none;
  }
}

._rim-filter-toggle .rims-page {
	.mobile-grid {
		display: block !important;
	}
}

.car-search-wrap {
  display: none;

  &._full {
    .car-search {
      &:not(._full) {
        display: none;
      }
      &._full {
        display: grid;
      }
    }
    button {
      display: none;
    }
  }

  button{
    display: block;
    width: 100%;
    @media(min-width: $mediaSm) {
      display: none;
    }
  }

  .car-search {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);

    &:not(._full) {
      @media(min-width: $mediaSm) {
        display: none;
      }
    }

    &._full {
      @media(max-width: $mediaSm) {
        display: none;
      }
    }

    a {
      color: #A20202;
    }

    @media (max-width: $mediaMd) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: $mediaSm) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: $media320) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}

body:not(._rim-filter-toggle) .rims-page .catalog-container {
	@media (min-width: $mediaLg) {
		display: flex;
	}

	#catalog-prod-wrap:not(._full) {
		&>* {
			@media (min-width: $mediaMd) {
				flex: 0 0 33.3%;
				max-width: 33.3%;
			}

			@media (min-width: $mediaLg) {
				flex: 0 0 33.4%;
				max-width: 33.4%;
			}

			@media (min-width: 1460.98px) {
				flex: 0 0 33%;
				max-width: 33%;
			}
		}

	}
}
