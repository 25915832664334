#popup-banner-container {
  display: none;

  &._visible {
    display: block;
  }
  
  #popup-banner-overlay {
    position: fixed;
    background-color: rgba(0, 0, 0, 0.685);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9997;
    display: block;
    overflow-y: hidden;
    overflow-x: hidden;
    cursor: pointer;
  }

  #popup-banner {
    position: fixed;
    z-index: 9998;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    .popup-banner-body {
      width: 100%;
      overflow: hidden;
      position: relative;
      z-index: 9999;

      @media(min-width: 400px) {
        width: 570px;
      }

      #popup-banner-close {
        position: absolute;
        /* margin-top: 8px; */
        /* margin-left: calc(100% - 42px); */
        display: flex;
        width: 100%;
        justify-content: flex-end;
        padding-top: 8px;
        padding-right: 8px;

        svg {
          width: 20px;
          height: 20px;
        }
      }

      img {
        object-fit: cover;
        width: 100%;
        height: 300px;
      }
    }
  }
}
