.component_review {
	.swiper-slide {
		height: auto; 
		a {
			height: 100%;
		}
	}
	.swiper {
		&-container {
			height: 100%;
			min-height: 300px;
			overflow: hidden;
		}

		&-pagination-wrap {
			bottom: 0;
			position: absolute;
			right: 0;
			
			button {
				height: 54px;
				width: 54px;
				position: relative;
				left: auto;
				right: auto;
				&::after {
					display: none;
				}
			}
		}
	}
	
	.header {
		border-bottom: 1px solid $colorAcent;
		
		svg {
			fill: $colorDark;
			height: 44px;
			width: 44px;
		}
	}
	
	.content {
		height: 100%;
		
		p {
			font-style: italic;
			line-height: 1.6;
		}
	}
	
	@media (max-width: $mediaLg) {
		.swiper {
			&-container {
				min-height: 240px;
			}
			
			&-pagination {
				&-wrap {
					button {
						height: 44px;
						width: 44px;
					}
				}
			}
		}
		
		.content {
			p {
				font-size: size(14);
			}
		}
	}
	
	@media (max-width: $mediaSm) {
		.swiper {
			&-container {
				min-height: 200px;
			}
		}
	}
	
	@media (max-width: $media475) {
		.swiper {
			&-container {
				min-height: 160px;
			}
		}
	}
}
