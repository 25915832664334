.section {
	padding-top: 70px;
	
	&-title {
		font-size: size(30);
		font-weight: 700;
		text-transform: uppercase;
		
		&-wrap {
			margin-bottom: 35px;
		}
	}

  .swiper-container {
    overflow: hidden;
  }

  .product-slider {
    padding-bottom: 20px;
    margin-bottom: -20px;
  }

  .product-slider > div {
    overflow: visible;
  }
	
	@media (max-width: $mediaLg) {
		padding-top: 50px !important;
	}
	
	@media (max-width: $mediaMd) {
		padding-top: 30px !important;
	}
}
